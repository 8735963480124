import { hostname } from "@/utils";

export const salesforceUrl = () => {
  switch (window.location.hostname) {
    // Prod
    case hostname.prod:
      return "https://smartrent.lightning.force.com";

    // QA
    case hostname.qa:
    case hostname.local:
      return "https://smartrent--full.sandbox.my.salesforce.com";

    // Default to test
    default:
      return "https://smartrent--full.sandbox.my.salesforce.com";
  }
};

// https://gist.github.com/step307/3d265b7c7cb4eccdf0cf55a68c9cfefa
export function isValidSfId(str) {
  // https://stackoverflow.com/a/29299786/1333724
  if (typeof str !== "string" || str.length !== 18) {
    return false;
  }

  const upperCaseToBit = (char) => (char.match(/[A-Z]/) ? "1" : "0");
  const binaryToSymbol = (digit) =>
    digit <= 25
      ? String.fromCharCode(digit + 65)
      : String.fromCharCode(digit - 26 + 48);

  const parts = [
    str.slice(0, 5).split("").reverse().map(upperCaseToBit).join(""),
    str.slice(5, 10).split("").reverse().map(upperCaseToBit).join(""),
    str.slice(10, 15).split("").reverse().map(upperCaseToBit).join(""),
  ];

  const check = parts.map((str) => binaryToSymbol(parseInt(str, 2))).join("");

  return check === str.slice(-3);
}
