import { QuickSightDashboard } from "@/common/QuicksightDashboard";
import { useDocumentTitle } from "@/hooks/use-document-title";

import {
  toQuickSightFilters,
  useDashboardFilterStore,
} from "../dashboards-filter-state";

export const ClientDashboardPage = () => {
  useDocumentTitle("Insights - Client Dashboard");

  const filtersQS = useDashboardFilterStore((store) =>
    toQuickSightFilters(store)
  );

  return (
    <QuickSightDashboard
      id="client-dashboard"
      filters={filtersQS}
      style={{ height: "calc(100vh - 208px)", width: "100%" }}
    />
  );
};
