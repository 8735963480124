import * as React from "react";
import { cva, type VariantProps } from "class-variance-authority";
import { StyleSheet } from "react-native";

import { IconProps } from "@smartrent/icons";

import { cn } from "@/_v2/utils";

const badgeVariants = cva(
  "inline-flex items-center rounded-md border px-2.5 py-0.5 text-xs font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2",
  {
    variants: {
      variant: {
        default: "border-transparent bg-primary text-primary-foreground shadow",
        secondary: "border-transparent bg-secondary text-secondary-foreground",
        info: "border-transparent bg-info text-primary-foreground",
        success: "border-transparent bg-success text-success-foreground shadow",
        destructive:
          "border-transparent bg-destructive text-destructive-foreground shadow",
        outline: "text-foreground",
      },
    },
    defaultVariants: {
      variant: "default",
    },
  }
);

export interface BadgeProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof badgeVariants> {
  icon?: React.ComponentType<React.PropsWithChildren<IconProps>>;
}

function Badge({ className, variant, icon: Icon, ...props }: BadgeProps) {
  return (
    <div className={cn(badgeVariants({ variant }), className)}>
      {Icon ? <Icon size={14} style={styles.icon} /> : null}
      <div {...props} />
    </div>
  );
}

const styles = StyleSheet.create({
  icon: {
    marginRight: 8,
  },
});

export { Badge, badgeVariants };
