export const links = {
  dashboard: () => "/dashboard",

  organizations: () => "/organizations",
  organization: (id: string | number) => `/organizations/${id}`,

  groups: () => "/groups",
  group: (id?: string | number) => `/groups/${id}`,

  unit: (id?: string | number) => `/units/${id}`,
  hub: (id?: string | number) => `/hubs/${id}`,

  users: () => "/users",
  user: (id?: string | number) => `/users/${id}`,
};
