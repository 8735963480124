import { useReducedInfiniteQuery } from "@smartrent/hooks";

import { createAxiosMutation, createAxiosQuery } from "@/hooks/react-query";

import { instance } from "@/lib/hooks";

import { GroupProps } from "../types";

interface GroupPaginatedResult {
  records: GroupProps[];
  total_records: number;
  total_pages: number;
  current_page: number;
}

export interface GroupListFilters {
  organization_id: number;
  marketing_name?: string;
  parking_enabled?: boolean;
  active?: boolean;
  limit?: number;
  dir?: "asc" | "desc";
  page?: number | string;
  missing_salesforce_id?: boolean;
}

export const useGroupsInfiniteQuery = (
  filters: Omit<GroupListFilters, "page">
) => {
  return useReducedInfiniteQuery<GroupPaginatedResult>(
    [
      "groups",
      {
        ...filters,
      },
    ],
    async ({ pageParam }) => {
      const { data } = await instance.get("/groups", {
        params: { ...filters, page: pageParam },
      });
      return data;
    }
  );
};

export const useUpdateGroupMutation = createAxiosMutation(
  async ({ id, ...formikValues }: Partial<GroupProps>) =>
    await instance.patch(`/groups/${id}`, formikValues)
);

export const useAllGroupsQuery = createAxiosQuery(
  "groups-for-salesforce-mapping",
  async (filters: GroupListFilters) => {
    return await instance.get<GroupPaginatedResult>(`/groups`, {
      params: { ...filters },
    });
  }
);
