import { useContext, useCallback } from "react";
import { useQueryClient, useQuery } from "@tanstack/react-query";

import { useMutationCompat } from "@smartrent/hooks";

import Context from "@/layout/Context";

import { AxiosMutationConfig } from "@/lib/react-query-helpers";
import {
  VendorApplication,
  VendorPermission,
  ListQueryResponse,
} from "@/types";
import { instance } from "@/lib/hooks";

interface CreateVendorApplicationOptions {
  organizationId: number;
  values: {
    name: string;
    key: string;
    max_codes_per_unit: number;
  };
}

interface DeleteVendorApplicationOptions {
  organizationId: number;
  vendorApplicationId: number;
}

interface AssignGroupsToVendorApplicationOptions {
  organizationId: number;
  groupIds: number[];
  vendorApplicationId: number;
}

interface UnassignGroupFromVendorApplicationOptions {
  organizationId: number;
  groupId: number;
  vendorApplicationId: number;
}

interface GrantOrRevokeVendorPermissionOptions {
  organizationId: number;
  vendorApplicationId: number;
  vendorPermissionId: number;
}

interface GetVendorApplicationSecretOptions {
  organizationId: number;
  vendorApplicationId: number;
}

export const useVendorApplicationsQuery = (organizationId: number) =>
  useQuery(
    ["vendor-applications", organizationId],
    async () => {
      const { data } = await instance.get<ListQueryResponse<VendorApplication>>(
        `/organizations/${organizationId}/vendor-applications`
      );

      return data;
    },
    {
      enabled: !!organizationId,
    }
  );

export const useInvalidateVendorApplicationsQuery = () => {
  const queryCache = useQueryClient();

  return useCallback(
    (organizationId: number) =>
      queryCache.invalidateQueries(["vendor-applications"]),
    [queryCache]
  );
};

export const useVendorApplicationQuery = (
  organizationId: number,
  vendorApplicationId: number
) =>
  useQuery(
    ["vendor-application", organizationId, vendorApplicationId],
    async () => {
      const { data } = await instance.get<VendorApplication>(
        `/organizations/${organizationId}/vendor-applications/${vendorApplicationId}`
      );

      return data;
    },
    {
      enabled: Boolean(organizationId && vendorApplicationId),
    }
  );

export const useInvalidateVendorApplicationQuery = () => {
  const queryCache = useQueryClient();

  return useCallback(
    (organizationId: number, vendorApplicationId: number) => {
      queryCache.invalidateQueries([
        "vendor-application",
        organizationId,
        vendorApplicationId,
      ]);
    },
    [queryCache]
  );
};

export const useCreateVendorApplicationMutation = (
  options?: AxiosMutationConfig<CreateVendorApplicationOptions>
) => {
  const context = useContext(Context);
  const invalidateVendorApplications = useInvalidateVendorApplicationsQuery();

  return useMutationCompat(
    async ({ organizationId, values }: CreateVendorApplicationOptions) => {
      try {
        const { data } = await instance.post(
          `/organizations/${organizationId}/vendor-applications`,
          values
        );

        context.setToast({
          type: "success",
          message: "Successfully created vendor application.",
        });

        invalidateVendorApplications(organizationId);

        return data;
      } catch (err) {
        context.setToast({
          type: "error",
          message: "Unable to create Vendor Application.",
        });

        throw err;
      }
    },
    options
  );
};

export const useDeleteVendorApplicationMutation = (
  options?: AxiosMutationConfig<DeleteVendorApplicationOptions>
) => {
  const context = useContext(Context);
  const invalidateVendorApplications = useInvalidateVendorApplicationsQuery();

  return useMutationCompat(
    async ({
      organizationId,
      vendorApplicationId,
    }: DeleteVendorApplicationOptions) => {
      try {
        const { data } = await instance.delete(
          `/organizations/${organizationId}/vendor-applications/${vendorApplicationId}`
        );

        context.setToast({
          type: "success",
          message: "Successfully deleted vendor application.",
        });

        invalidateVendorApplications(organizationId);

        return data;
      } catch (err) {
        context.setToast({
          type: "error",
          message: "Unable to delete vendor application",
        });

        throw err;
      }
    },
    options
  );
};

export const useAssignGroupsToVendorApplicationMutation = (
  options?: AxiosMutationConfig<AssignGroupsToVendorApplicationOptions>
) => {
  const context = useContext(Context);
  const invalidateVendorApplication = useInvalidateVendorApplicationQuery();

  return useMutationCompat(
    async ({
      organizationId,
      groupIds,
      vendorApplicationId,
    }: AssignGroupsToVendorApplicationOptions) => {
      try {
        const response = await instance.post(
          `/organizations/${organizationId}/vendor-applications/${vendorApplicationId}/groups`,
          { groupIds }
        );

        context.setToast({
          type: "success",
          message: "Successfully assigned group.",
        });

        invalidateVendorApplication(organizationId, vendorApplicationId);

        return response;
      } catch (err) {
        context.setToast({
          type: "error",
          message: "Unable to assign group.",
        });

        throw err;
      }
    },
    options
  );
};

export const useUnassignGroupFromVendorApplicationMutation = (
  options?: AxiosMutationConfig<UnassignGroupFromVendorApplicationOptions>
) => {
  const context = useContext(Context);
  const invalidateVendorApplication = useInvalidateVendorApplicationQuery();

  return useMutationCompat(
    async ({
      organizationId,
      groupId,
      vendorApplicationId,
    }: UnassignGroupFromVendorApplicationOptions) => {
      try {
        const response = await instance.delete(
          `/organizations/${organizationId}/vendor-applications/${vendorApplicationId}/groups/${groupId}`
        );

        context.setToast({
          type: "success",
          message: "Successfully unassigned group.",
        });

        invalidateVendorApplication(organizationId, vendorApplicationId);

        return response;
      } catch (err) {
        context.setToast({
          type: "error",
          message: "Unable to unassign group.",
        });

        throw err;
      }
    },
    options
  );
};

export const useAvailableVendorPermissionsQuery = () =>
  useQuery(
    ["vendor-permissions"],
    async () => {
      const { data } = await instance.get<VendorPermission[]>(
        `/vendor-applications/permissions`
      );

      return data;
    },
    {
      enabled: true,
    }
  );

export const useGrantVendorPermissionMutation = (
  options?: AxiosMutationConfig<GrantOrRevokeVendorPermissionOptions>
) => {
  const invalidateVendorApplication = useInvalidateVendorApplicationQuery();

  return useMutationCompat(
    async ({
      organizationId,
      vendorApplicationId,
      vendorPermissionId,
    }: GrantOrRevokeVendorPermissionOptions) => {
      try {
        const response = await instance.post(
          `/organizations/${organizationId}/vendor-applications/${vendorApplicationId}/permissions`,
          { vendorPermissionId }
        );

        invalidateVendorApplication(organizationId, vendorApplicationId);

        return response;
      } catch (err) {
        throw err;
      }
    },
    options
  );
};

export const useRevokeVendorPermissionMutation = (
  options?: AxiosMutationConfig<GrantOrRevokeVendorPermissionOptions>
) => {
  const invalidateVendorApplication = useInvalidateVendorApplicationQuery();

  return useMutationCompat(
    async ({
      organizationId,
      vendorApplicationId,
      vendorPermissionId,
    }: GrantOrRevokeVendorPermissionOptions) => {
      try {
        const response = await instance.delete(
          `/organizations/${organizationId}/vendor-applications/${vendorApplicationId}/permissions/${vendorPermissionId}`
        );

        invalidateVendorApplication(organizationId, vendorApplicationId);

        return response;
      } catch (err) {
        throw err;
      }
    },
    options
  );
};

export const useVendorApplicationSecretQuery = (
  options?: AxiosMutationConfig<GetVendorApplicationSecretOptions>
) => {
  return useMutationCompat(
    async ({
      organizationId,
      vendorApplicationId,
    }: GetVendorApplicationSecretOptions) => {
      try {
        const response = await instance.get(
          `/organizations/${organizationId}/vendor-applications/${vendorApplicationId}/secret`
        );

        return response;
      } catch (err) {
        throw err;
      }
    },
    options
  );
};
