import { get, last } from "lodash-es";
import moment from "moment";
import { Line } from "react-chartjs-2";

import { useUser } from "@/layout/Context";

import helpers from "@/lib/helpers";

interface HubReportRecord {
  inserted_at: string;
  online: number;
  offline: number;
}

interface Props {
  hubs: HubReportRecord[];
}

export default function HubsOnline({ hubs }: Props) {
  const { permissions } = useUser();

  const daysOfYearRepresented = [];

  const datasets = [];

  const lastOffline = Number(get(last(hubs), "offline", 0));
  const lastOnline = Number(get(last(hubs), "online", 0));

  if (permissions.manage_system_health) {
    datasets.push({
      ...helpers.lineGraphDefaults,

      label: `Offline Hubs (${lastOffline.toLocaleString()}, ${(
        (lastOffline / (lastOnline + lastOffline)) *
        100
      ).toFixed(2)}%)`,
      // yAxisID: "B",
      data: hubs.map(({ offline, online }) =>
        Number((offline / (online + offline)) * 100).toFixed(2)
      ),
      backgroundColor: helpers.lineGraphColors.red,
      borderColor: helpers.lineGraphColors.red,
      pointBorderColor: helpers.lineGraphColors.red,
      pointHoverBackgroundColor: helpers.lineGraphColors.red,
      pointHoverBorderColor: "rgba(220,220,220,1)",
    });
  }

  datasets.push({
    ...helpers.lineGraphDefaults,
    label: `Online Hubs (${lastOnline.toLocaleString()}, ${(
      (lastOnline / (lastOnline + lastOffline)) *
      100
    ).toFixed(2)}%)`,
    // yAxisID: "A",
    data: hubs.map(({ online, offline }) =>
      Number((online / (online + offline)) * 100).toFixed(2)
    ),
    backgroundColor: helpers.lineGraphColors.green,
    borderColor: helpers.lineGraphColors.green,
    pointBorderColor: helpers.lineGraphColors.green,
    pointHoverBackgroundColor: helpers.lineGraphColors.green,
    pointHoverBorderColor: "rgba(220,220,220,1)",
  });

  return (
    <Line
      data={{
        labels: hubs.map(({ inserted_at }) => {
          const dayOfYear = moment(inserted_at).dayOfYear();
          if (!daysOfYearRepresented.includes(dayOfYear)) {
            daysOfYearRepresented.push(dayOfYear);
            return moment(inserted_at).format("MM/DD hh:mm a");
          } else {
            return moment(inserted_at).format("hh:mm a");
          }
        }),
        datasets,
      }}
      options={{
        tooltips: {
          callbacks: {
            label: function (tooltipItem: any, _data: any) {
              return _data.datasets[tooltipItem.datasetIndex].label;
            },
          },
        },
      }}
      height={45}
    />
  );
}
