import { useState, useEffect, useMemo } from "react";
import { get, groupBy, transform, orderBy } from "lodash-es";
import {
  Typography,
  Panel,
  PanelBody,
  HStack,
  Chip,
  useTheme,
} from "@smartrent/ui";
import { StyleSheet, View } from "react-native";

import Helpers from "@/lib/helpers";
import { useUser } from "@/layout/Context";

import { useApi } from "@/lib/hooks";

import Button from "@/common/Button";
import Alert from "@/common/Alert";
import Circle from "@/common/Circle";

import { useUnit } from "./UnitContext";

export default function Activity() {
  const { permissions } = useUser();
  const { unit } = useUnit();

  const [activity, setActivity] = useState(null);
  const [moreRecords, setMoreRecords] = useState(true);
  const [page, setPage] = useState(1);

  const { colors } = useTheme();

  const canViewUnits = permissions.units;
  const elevatedActivity = permissions.elevated_activity;

  const { response, reFetch } = useApi({
    url: `/units/${unit.id}/activity?${Helpers.toQS({
      page,
    })}`,
  });

  useEffect(() => {
    if (get(response, "data.records.length", 0)) {
      setActivity([...(activity || []), ...response.data.records]);

      if (response.data.total_pages > page) {
        setPage(page + 1);
      } else {
        setMoreRecords(false);
      }
    }
  }, [response]);

  const groupedActivity = useMemo(() => {
    /* Gnarly callback hell, but it does what we want

    Sample data:
    
    [
      {key: "Some Device", activity: [{key: "Some Event", count: 8, key: "Some Other Event", count: 7}], count: 9},
      {key: "Some Other Device", activity: [...], count: 8},
    ]
    */
    return orderBy(
      transform(
        groupBy(activity, (a) => {
          return a.subject;
        }),
        (acc, val, key) => {
          const groupedActivity = orderBy(
            transform(
              groupBy(val, (a) => a.description),
              (acc2, val2, key2) => {
                acc2.push({ key: key2, count: val2.length });
              },
              []
            ),
            ["count"],
            ["desc"]
          );

          acc.push({ key: key, activity: groupedActivity, count: val.length });
          return acc;
        },
        []
      ),
      ["count"],
      ["desc"]
    );
  }, [activity]);

  if (!canViewUnits) {
    return null;
  }

  return (
    <div>
      {groupedActivity?.length > 0 ? (
        <Panel>
          <PanelBody>
            <>
              <Typography type="title3" style={styles.insightHeader}>
                Activity Log - Insights
              </Typography>

              {groupedActivity.map(({ key, activity }) => {
                return (
                  <View key={key} style={styles.deviceInsight}>
                    <Typography type="title4">{key}</Typography>

                    {activity.map((a) => {
                      return (
                        <View key={a.key} style={styles.eventInsight}>
                          <HStack spacing={8} align="center">
                            <Chip color="info" variation="muted">
                              {a.count > 99 ? 99 : a.count}
                            </Chip>

                            <Typography>{a.key}</Typography>
                          </HStack>
                        </View>
                      );
                    })}
                  </View>
                );
              })}
            </>
          </PanelBody>
        </Panel>
      ) : null}
      {activity &&
        activity.map(
          ({
            description,
            subject,
            inserted_at,
            id,
            caused_by,
            failed,
            confirmed_at,
          }) => {
            return (
              <div
                key={id}
                className="u-top8 u-line18 u-flex-align-items-end u-p20"
                style={{
                  padding: "8px",
                  borderBottom: "1px solid #eee",
                }}
              >
                <div>
                  {confirmed_at ? (
                    <Circle size={6} color={Helpers.colors.green} />
                  ) : null}{" "}
                  {failed ? (
                    <Circle size={6} color={Helpers.colors.red} />
                  ) : null}{" "}
                  {subject} - {description}{" "}
                  {caused_by === "schedule" ? "(schedule)" : null}
                </div>

                <div className="u-font14" style={{ color: colors.textPrimary }}>
                  {Helpers.formatDate(inserted_at, "ddd, MM/DD hh:mm:ssa")}
                </div>
              </div>
            );
          }
        )}

      {moreRecords && (
        <Button
          onClick={() => reFetch()}
          className="button button--primary u-mtop8"
        >
          {!response ? "Fetch Activity" : "More Activity"}
        </Button>
      )}

      {activity && activity.length && elevatedActivity && (
        <Alert type="warning">
          You have elevated permissions to debug unit problems and can see
          Activity data generated by the resident(s). Do NOT share or disclose
          information from this Activity feed.
        </Alert>
      )}
    </div>
  );
}

const styles = StyleSheet.create({
  deviceInsight: {
    marginBottom: 8,
  },
  eventInsight: {
    marginBottom: 4,
  },
  insightHeader: {
    marginBottom: 8,
  },
});
