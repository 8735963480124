import { create } from "zustand";
import { DateRangeValue, DateValue } from "@smartrent/forms";

import { GroupProps, OrganizationProps } from "@/types";
import { filtersToQS } from "@/lib/helpers";

interface DashboardFilters {
  organizations?: OrganizationProps[];
  groups?: GroupProps[];
  dateRange?: DateRangeValue;
}

interface DashboardFiltersTransformed {
  organization_ids?: string[];
  group_ids?: string[];
  start_date?: string;
  end_date?: string;
}

interface DashboardFilterStore {
  filters: DashboardFilters;
  transformedFilters: DashboardFiltersTransformed;
  filtersQS: string;
  activeFilterStatuses: { [key: string]: boolean };
  setFilters: (filters: DashboardFilters) => void;
  reset: () => void;
  setFilterActiveStatus: (filter: string, activeState: boolean) => void;
}

export const useDashboardFilterStore = create<DashboardFilterStore>((set) => ({
  filters: {},
  transformedFilters: {},
  filtersQS: "",
  activeFilterStatuses: {},
  setFilters: (filters) =>
    set({
      filters,
      transformedFilters: transformFilters(filters),
      filtersQS: filtersToQueryString(filters),
    }),
  reset: () => set({ filters: {}, transformedFilters: {}, filtersQS: "" }),
  setFilterActiveStatus: (filter, activeState) =>
    set((state) => ({
      activeFilterStatuses: {
        ...state.activeFilterStatuses,
        [filter]: activeState,
      },
    })),
}));

export const transformFilters = (
  filters: DashboardFilters
): DashboardFiltersTransformed => ({
  organization_ids: filters.organizations?.map((o) => o.id),
  group_ids: filters.groups?.map((g) => `${g.id}`),
  start_date: dateValueToDate(filters.dateRange?.[0]),
  end_date: dateValueToDate(filters.dateRange?.[1]),
});

const dateValueToDate = (dateRange?: Partial<DateValue>) => {
  if (!dateRange) {
    return undefined;
  }

  const { year, month, day } = dateRange;
  if (!year || !month || !day) {
    return undefined;
  }

  return new Date(year, month - 1, day).toISOString();
};

const filtersToQueryString = (filters: DashboardFilters) => {
  const transformedFilters = transformFilters(filters);

  const qs = filtersToQS(
    Object.entries(transformedFilters).map(([id, value]) => ({ id, value }))
  );

  return qs ? `?${qs}` : "";
};

export const toQuickSightFilters = ({
  filters,
  transformedFilters,
}: DashboardFilterStore) => {
  const defaultStartDate = new Date();
  defaultStartDate.setMonth(defaultStartDate.getMonth() - 3);
  const defaultEndDate = new Date();
  const qFilters = [
    {
      Name: "cmwAccount",
      Values:
        filters.organizations && filters.organizations.length > 0
          ? filters.organizations.map((o) => o.name)
          : ["all"],
    },
    {
      Name: "cmwProperty",
      Values:
        filters.groups && filters.groups.length > 0
          ? filters.groups.map((g) => g.marketing_name)
          : ["all"],
    },
    {
      Name: "startDate",
      Values: [transformedFilters.start_date || defaultStartDate.toISOString()],
    },
    {
      Name: "endDate",
      Values: [transformedFilters.end_date || defaultEndDate.toISOString()],
    },
  ];

  return qFilters;
};
