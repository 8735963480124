import React from "react";
import { CheckCircledIcon, CopyIcon } from "@radix-ui/react-icons";

import { Button } from "@/_v2/components/ui/button";
import { cn } from "@/_v2/utils";
import { useCopyText } from "@/_v2/utils/hooks/use-copy-text";

export interface CopyTextButtonProps {
  text: string;
  className?: string;
  iconClassName?: string;
}

/**
 * UI component that copies text to the clipboard when clicked. Shows icons for copy and success.
 * Use this when text is shown in another component and you want to allow users to copy it.
 */
export const CopyTextButton: React.FC<
  React.PropsWithChildren<CopyTextButtonProps>
> = ({ text, className, iconClassName, children }) => {
  const { copyText, successState } = useCopyText();

  const icon = successState ? (
    <CheckCircledIcon className={cn("size-4", iconClassName)} />
  ) : (
    <CopyIcon className={cn("size-4", iconClassName)} />
  );

  return (
    <>
      <Button
        variant="invisible"
        size="icon"
        onClick={() => copyText(text)}
        className={className}
      >
        {children ? (
          <div className="flex items-center">
            {children}
            {icon}
          </div>
        ) : (
          icon
        )}
      </Button>
    </>
  );
};
