import { UserProps } from "@/types";
import { hostname } from "@/utils";

const cmwDomain = () => {
  switch (window.location.hostname) {
    // Prod
    case hostname.prod:
      return "https://control.smartrent.com";

    // QA
    case hostname.qa:
      return "https://control.smartrent-qa.com";

    // Local
    case hostname.local:
      return "http://localhost:4000";

    // Default to prod
    default:
      return "https://control.smartrent.com";
  }
};
const toursDomain = () => import.meta.env.REACT_APP_TOURS_BASE_URL;
const supportDomain = () => "/";
const builder = {
  cmw: (
    path: string,
    { withBaseUrl }: { withBaseUrl: boolean } = { withBaseUrl: true }
  ) => (withBaseUrl ? `${cmwDomain()}${path}` : path),
  tours: (path: string) => `${toursDomain()}${path}`,
  support: (path: string) => `${supportDomain()}${path}`,
};

// SUPPORT
export const apiUrl = `${import.meta.env.REACT_APP_BASE_API_URL}/api`;
export const alloyInstallUserDetailLink = (userId: UserProps["id"]) =>
  builder.support(`alloy-install/users/${userId}`);
export const unit = (id: string | number) => `/units/${id}`;
export const group = (id: string | number) => `/groups/${id}`;
export const groups = "/groups";
export const organization = (id: string | number) => `/organizations/${id}`;
export const organizations = "/organizations";
export const residentWebhooks = (
  residentId: string | number,
  userFirstName: string,
  userLastName: string,
  unitMarketingName: string
) =>
  `/residents/${residentId}/webhooks?user_full_name=${
    userFirstName && userLastName
      ? encodeURIComponent(userFirstName + " " + userLastName)
      : ""
  }&unit_marketing_name=${
    unitMarketingName ? encodeURIComponent(unitMarketingName) : ""
  }`;

// CMW
export const cmwResidentOverview = (
  groupId: string | number,
  unitId: string | number,
  residentId: string | number
) => `/manager/groups/${groupId}/units/${unitId}/residents/${residentId}`;
export const cmwUnitOverview = (unitId: string | number) =>
  builder.cmw(`/manager/units/${unitId}/overview`);
export const userInvitationLink = (token: string) =>
  builder.cmw(`/invite/${token}`);
export const userForgotPasswordLink = (token: string) =>
  builder.cmw(`/forgot-password/${token}`);
export const cmwRedirectBuilder = (
  path: string,
  organizationUrl: string,
  { withBaseUrl }: { withBaseUrl: boolean } = { withBaseUrl: true }
) =>
  builder.cmw(`/support/redirect/${organizationUrl}?redirect_to=${path}`, {
    withBaseUrl,
  });
export const cmwScopeOfWorkExportLink = (uuid: string) =>
  builder.cmw(`/p/scope-of-work/${uuid}`);

// TOURS
export const groupsTourUrl = ({ uuid }: { uuid?: string }) =>
  builder.tours(`/property/${uuid}/units`);
export const homeTourUrl = ({
  uuid,
  unit_id,
}: {
  uuid?: string;
  unit_id?: string;
}) => builder.tours(`/property/${uuid}/unit_ids?=${unit_id}`);
