import { formatDate, isDecentHoursToCall } from "@/utils";

import { useCurrentUnit } from "@/_v2/api/units";

import { Card } from "@/_v2/components/ui/card";
import { Badge } from "@/_v2/components/ui/badge";
import { Skeleton } from "@/_v2/components/ui/skeleton";
import {
  Tooltip,
  TooltipTrigger,
  TooltipContent,
} from "@/_v2/components/ui/tooltip";

import { UnitImage } from "./unit-image";
import { UnitPageDetailsDrawer } from "./unit-page-details-drawer";

export const UnitPageDetails = () => {
  return (
    <div className="relative z-0">
      <Card className="h-14 p-1 flex justify-between items-center space-x-2">
        <UnitImage />

        <div className="flex flex-col">
          <UnitAddress />

          <div className="flex space-x-1">
            <UnitStatus />
            <UnitTime />
          </div>
        </div>

        <div className="flex-1" />

        <UnitPageDetailsDrawer />
      </Card>
    </div>
  );
};

const UnitAddress = () => {
  const { data, isFetching } = useCurrentUnit();

  if (!data || isFetching) {
    return <Skeleton className="h-5 w-72  mb-1" />;
  }

  const { unit } = data;

  return (
    <p className="text-sm space-x-2">
      <span>
        {unit.building ? `Building ${unit.building}` : ""}
        {unit.building && unit.floor ? ", " : ""}
        {unit.floor ? `Floor ${unit.floor}` : ""}
      </span>
      <span className="text-muted-foreground">
        {unit.street_address_1}
        {unit.street_address_2 ? " " : ""}
        {unit.street_address_2},{"\n"}
        {unit.city}, {unit.state} {unit.zip} {unit.country}
      </span>
    </p>
  );
};

const UnitStatus = () => {
  const { data, isFetching } = useCurrentUnit();

  if (!data || isFetching) {
    return <Skeleton className="h-5 w-16" />;
  }

  const { unit } = data;

  return (
    <Tooltip>
      <TooltipTrigger>
        <Badge variant="secondary">{unit.status}</Badge>
      </TooltipTrigger>
      <TooltipContent>
        <div>
          <span className="text-muted-foreground">Unit Status:</span>{" "}
          {unit.status}
        </div>
        <div>
          <span className="text-muted-foreground">Occupancy:</span>{" "}
          {unit.is_occupied ? "Is Occupied" : "Not Occupied"}
        </div>
      </TooltipContent>
    </Tooltip>
  );
};

const UnitTime = () => {
  const { data, isFetching } = useCurrentUnit();

  if (!data || isFetching) {
    return <Skeleton className="h-5 w-16" />;
  }

  const { unit } = data;

  const isDecentHours = isDecentHoursToCall(unit.timezone);
  const decentHourColor = isDecentHours ? "success" : "destructive";

  return (
    <Tooltip>
      <TooltipTrigger>
        <Badge variant={unit.timezone ? decentHourColor : "secondary"}>
          {formatDate({
            date: unit.timezone ? new Date().toISOString() : null,
            pattern: "p",
            timeZone: unit.timezone,
          })}
        </Badge>
      </TooltipTrigger>
      <TooltipContent>
        {unit.timezone ? (
          <>
            <div>
              <span className="text-muted-foreground">Local Time:</span>{" "}
              {formatDate({
                date: new Date().toISOString(),
                pattern: "p",
                timeZone: unit.timezone,
              })}
            </div>
            <div>
              <span className="text-muted-foreground">Timezone:</span>{" "}
              {unit.timezone}
            </div>
            <div>
              <span className="text-muted-foreground">Decent Hours:</span>{" "}
              {`Right now, ${
                isDecentHours
                  ? "it should be ok to contact residents."
                  : "it's not a good time to contact residents."
              }`}
            </div>
          </>
        ) : (
          <span>The unit's timezone is not set.</span>
        )}
      </TooltipContent>
    </Tooltip>
  );
};
