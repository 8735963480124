import { useUser } from "@/layout/Context";
import { useCurrentUnit } from "@/_v2/api/units";
import { CopyValue } from "@/_v2/components/composites/copy-value";

export const UnitPageDbInfo: React.FC = () => {
  const { permissions } = useUser();

  const { data: { unit, group, organization } = {} } = useCurrentUnit();

  if (!permissions.view_technical_details) {
    return null;
  }

  return (
    <div className="flex space-x-2 text-warning text-xs p-1">
      <CopyValue value={unit?.id}>unit id: {unit?.id}</CopyValue>
      <CopyValue value={unit?.remote_id}>
        remote id: {unit?.remote_id}
      </CopyValue>
      <CopyValue value={group?.id}>group id: {group?.id}</CopyValue>
      <CopyValue value={group?.property_code}>
        property code: {group?.property_code}
      </CopyValue>
      <CopyValue value={organization?.id}>org id: {organization?.id}</CopyValue>
    </div>
  );
};
