import { PermissionsProp } from "@/types";

/**
 * ENGINEERS:
 *
 * Please remember to work with Product Managers on which roles should have which permissions when adding new
 * permissions.
 *
 * When you have that information, please add the permission to this file, then update `roles-and-permissions.ts`
 */

enum PermissionType {
  General = "general",
  Parking = "parking",
  Reports = "reports",
  AccountsAndPii = "accounts_and_pii",
  EngineeringAndDevops = "engineering_and_devops",
  VendorApi = "vendor_api",
  Hubs = "hubs",
  Installs = "installs",
  Devices = "devices",
  Elevated = "elevated",
  Tours = "tours",
  SOW = "projects",
}

export interface GROUPED_PERMISSIONS {
  name: PermissionsProp;
  label: string;
  type: PermissionType;
  disabled?: boolean; // used to feature flag UI components behind permissions
}

// Permission Types
export const PERMISSIONS: GROUPED_PERMISSIONS[] = [
  // GENERAL
  {
    name: "search",
    label: "View / Use Searchbar",
    type: PermissionType.General,
  },
  {
    name: "search_fuzzy",
    label: "View / Use Fuzzy Searches",
    type: PermissionType.AccountsAndPii,
  },
  {
    name: "view_groups",
    label: "View Groups Table",
    type: PermissionType.General,
  },
  {
    name: "view_organizations",
    label: "View Organizations Table",
    type: PermissionType.General,
  },
  { name: "units", label: "View Units", type: PermissionType.General },
  {
    name: "view_users",
    label: "View Users Table",
    type: PermissionType.General,
  },
  {
    name: "update_organization",
    label: "Update Organization Settings",
    type: PermissionType.General,
  },
  {
    name: "id_integration_manager",
    label: "Link Integrations",
    type: PermissionType.General,
  },
  {
    name: "update_group",
    label: "Update Community Settings",
    type: PermissionType.General,
  },

  {
    name: "feature_flags",
    label: "Manage Feature Flags",
    type: PermissionType.General,
  },
  {
    name: "white_label_config",
    label: "Manage Community ResApp ReBranding",
    type: PermissionType.General,
  },
  {
    name: "manage_community_wifi",
    label: "Manage Community WiFi",
    type: PermissionType.General,
  },
  // PARKING
  {
    name: "manage_parking",
    label: "Manage Parking",
    type: PermissionType.Parking,
  },
  {
    name: "manage_parking_payments",
    label: "Manage Parking Payments",
    type: PermissionType.Parking,
  },
  // REPORTS
  {
    name: "can_send_organization_reports",
    label: "Send Organization Scheduled Reports",
    type: PermissionType.Reports,
  },
  {
    name: "reporting",
    label: "Can View SupportRent Reports",
    type: PermissionType.Reports,
  },
  {
    name: "download_first_response_codes",
    label: "Can Download First Responder Codes",
    type: PermissionType.Reports,
  },
  // HUBS
  {
    name: "troubleshoot",
    label: "Troubleshoot Hubs / Devices, See Deleted Devices",
    type: PermissionType.Hubs,
  },
  { name: "hub_prep", label: "Use Hub Prep", type: PermissionType.Hubs },
  {
    name: "hub_extended_details",
    label: "Hub Account Info",
    type: PermissionType.Hubs,
  },
  {
    name: "refurbish_hubs",
    label: "Can Refurbish Hubs",
    type: PermissionType.Hubs,
  },
  {
    name: "advanced_hub_troubleshooting",
    label: "Advanced Hub Troubleshooting",
    type: PermissionType.Hubs,
  },
  {
    name: "delete_construction_codes",
    label: "Delete Construction Codes",
    type: PermissionType.Hubs,
  },
  // INSTALLS
  {
    name: "work_orders",
    label: "View Work Orders",
    type: PermissionType.Installs,
  },
  {
    name: "view_installs",
    label: "View Alloy Install Data",
    type: PermissionType.Installs,
  },

  // Resident / CMW User Management
  {
    name: "resident_details",
    label:
      "View Contact Information (Residents, Prospects, Maintenance, Users)",
    type: PermissionType.AccountsAndPii,
  },
  {
    name: "resend_invites",
    label: "Resend Invites",
    type: PermissionType.AccountsAndPii,
  },
  {
    name: "view_masked_user_detail",
    label: "View Masked User Detail",
    type: PermissionType.AccountsAndPii,
  },
  {
    name: "merge_duplicate_residents",
    label: "Merge Duplicate Residents",
    type: PermissionType.AccountsAndPii,
  },
  {
    name: "delete_resident",
    label: "Delete Resident",
    type: PermissionType.AccountsAndPii,
  },
  {
    name: "account_management",
    label: "CMW Resident/User Management",
    type: PermissionType.AccountsAndPii,
  },
  {
    name: "change_user_email",
    label: "Change User Email",
    type: PermissionType.AccountsAndPii,
  }, // can change emails
  {
    name: "delete_user_accounts",
    label: "Delete CMW User Accounts",
    type: PermissionType.AccountsAndPii,
  },
  {
    name: "tfa_assistance",
    label: "TFA Assistance",
    type: PermissionType.AccountsAndPii,
  },
  {
    name: "unlink_user_record",
    label: "Unlink User Record",
    type: PermissionType.AccountsAndPii,
  },
  {
    name: "remove_service_account",
    label: "Remove Service Account",
    type: PermissionType.AccountsAndPii,
  },

  // DEVICES
  {
    name: "vault_codes",
    label: "View Vault Codes",
    type: PermissionType.Devices,
  },
  {
    name: "emergency_codes",
    label: "View Backup Codes",
    type: PermissionType.Devices,
  },
  {
    name: "device_settings",
    label: "Modify Device Settings",
    type: PermissionType.Devices,
  },
  {
    name: "view_device_identifiers",
    label: "View Device Identifiers",
    type: PermissionType.Devices,
  },
  {
    name: "apply_community_wifi",
    label: "Apply WiFi Device Network",
    type: PermissionType.Devices,
  },

  {
    name: "rotate_vacant_access_codes",
    label: "Rotate Vacant Access Codes",
    type: PermissionType.Devices,
  },

  // ELEVATED
  {
    name: "integration_credentials_v2",
    label: "View Integration Credentials",
    type: PermissionType.Elevated,
  },
  {
    name: "modify_permissions",
    label: "Modify Permissions",
    type: PermissionType.Elevated,
  },
  {
    name: "elevated_activity",
    label: "Elevated Activity Data",
    type: PermissionType.Elevated,
  }, // should be off for almost everyone

  {
    name: "account_links",
    label: "View Invite, Forgot Password Links",
    type: PermissionType.Elevated,
  }, // can view external link pages (passwords, )
  {
    name: "core_platform_access",
    label: "Core Platform Access",
    type: PermissionType.Elevated,
  },
  {
    name: "property_transfer",
    label: "Property Transfer",
    type: PermissionType.Elevated,
  },
  {
    name: "lock_pms",
    label: "Lock PMS",
    type: PermissionType.Elevated,
  },

  {
    name: "manage_system_health",
    label: "System Health Dashboards and Reporting",
    type: PermissionType.EngineeringAndDevops,
  },
  {
    name: "view_technical_details",
    label: "DataDog Links, Auto-increment IDs, etc.",
    type: PermissionType.EngineeringAndDevops,
  },
  {
    name: "rotate_signing_keys",
    label: "Rotate Signing Keys",
    type: PermissionType.EngineeringAndDevops,
  },

  // TOURS
  {
    name: "manual_verifications",
    label: "Manage ID Verifications",
    type: PermissionType.Tours,
  },
  // SOWs
  {
    name: "manage_sow",
    label: "Manage SOW",
    type: PermissionType.SOW,
  },
  {
    name: "view_sow",
    label: "View SOW",
    type: PermissionType.SOW,
  },
  {
    name: "delete_sow",
    label: "Delete SOW",
    type: PermissionType.SOW,
  },
  {
    name: "debug_sow",
    label: "Debug SOW",
    type: PermissionType.SOW,
  },

  // VENDORS
  {
    name: "view_vendor_applications",
    label: "View Community Vendors / Partners",
    type: PermissionType.VendorApi,
  },
  {
    name: "modify_vendor_applications",
    label: "Manage Community Vendors / Partners",
    type: PermissionType.VendorApi,
  },

  // DASHBOARD
  {
    name: "view_smartrent_dashboards",
    label: "View SmartRent Dashboards",
    type: PermissionType.General,
  },
  {
    name: "view_smartrent_client_dashboard",
    label: "View SmartRent Client Dashboard",
    type: PermissionType.General,
  },
];
