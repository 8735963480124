import { useContext } from "react";
import { last, find } from "lodash-es";
import moment from "moment";
import { Line } from "react-chartjs-2";

import Graph from "@/common/Graph";
import Context, { useUser } from "@/layout/Context";
import Helpers from "@/lib/helpers";

export default function ToursByDay({ filters }: { filters?: string }) {
  const { isDesktop } = useContext(Context);
  const { permissions } = useUser();

  if (!isDesktop) {
    return null;
  }

  // may change in future if we need to break this out
  const canViewUnfinalized = permissions.resident_details;

  return (
    <Graph
      path="/reports/tours"
      pathSearch={filters}
      dataKey="tours"
      leftFooterRenderProps={({ tourCount, prospectCount }) => {
        return (
          <div>
            <div>
              <span className="u-sans-bold">Total Finalized Tours</span>:{" "}
              {Number(tourCount.finalized_tours ?? 0).toLocaleString()}
            </div>
            {canViewUnfinalized && (
              <div>
                <span className="u-sans-bold">Total Unfinalized Tours</span>:{" "}
                {Number(tourCount.unfinalized_tours ?? 0).toLocaleString()}
              </div>
            )}
            <div>
              <span className="u-sans-bold">Total Prospects</span>:{" "}
              {Number(prospectCount ?? 0).toLocaleString()}
            </div>
          </div>
        );
      }}
      renderProps={({ tours, prospects }) => {
        const mapped = {
          prospects: [],
          finalizedTours: [],
          unfinalizedTours: [],
        };
        const dates = tours.map(({ date }) => date);

        dates.forEach((date) => {
          const prospect = find(prospects, { date });
          const tour = find(tours, { date });
          mapped.prospects.push(prospect ? Number(prospect.count) : 0);
          mapped.finalizedTours.push(tour ? Number(tour.finalized_tours) : 0);
          mapped.unfinalizedTours.push(
            tour ? Number(tour.unfinalized_tours) : 0
          );
        });

        const datasets = [
          {
            ...Helpers.lineGraphDefaults,
            label: `Finalized Tours (${Number(
              last(mapped.finalizedTours) ?? 0
            ).toLocaleString()})`,
            data: mapped.finalizedTours,
            backgroundColor: Helpers.lineGraphColors.darkGreen,
            borderColor: Helpers.lineGraphColors.darkGreen,
            pointBorderColor: Helpers.lineGraphColors.darkGreen,
            pointHoverBackgroundColor: Helpers.lineGraphColors.darkGreen,
            pointHoverBorderColor: Helpers.lineGraphColors.darkGreen,
          },
          {
            ...Helpers.lineGraphDefaults,
            fill: false,
            label: `New Prospects (${Number(
              last(mapped.prospects) ?? 0
            ).toLocaleString()})`,
            data: mapped.prospects,
            backgroundColor: Helpers.lineGraphColors.blue,
            borderColor: Helpers.lineGraphColors.blue,
            pointBorderColor: Helpers.lineGraphColors.blue,
            pointHoverBackgroundColor: Helpers.lineGraphColors.blue,
            pointHoverBorderColor: Helpers.lineGraphColors.blue,
          },
        ];

        if (canViewUnfinalized) {
          datasets.push({
            ...Helpers.lineGraphDefaults,
            label: `Unfinalized Tours (${Number(
              last(mapped.unfinalizedTours) ?? 0
            ).toLocaleString()})`,
            data: mapped.unfinalizedTours,
            backgroundColor: Helpers.lineGraphColors.gray200,
            borderColor: Helpers.lineGraphColors.gray200,
            pointBorderColor: Helpers.lineGraphColors.gray200,
            pointHoverBackgroundColor: Helpers.lineGraphColors.gray200,
            pointHoverBorderColor: Helpers.lineGraphColors.gray200,
          });
        }

        return (
          <Line
            redraw={true}
            data={{
              labels: tours.map((r) => moment.utc(r.date).format("ddd MM/DD")),
              datasets,
            }}
            height={45}
          />
        );
      }}
    />
  );
}
