import { View } from "react-native";

import { useTheme } from "@smartrent/ui";

interface Props {
  percentage: number;
}
export default function ProgressBar({ percentage }: Props) {
  const { colors } = useTheme();
  return (
    <View
      style={{
        backgroundColor: colors.mutedBackgroundGrayscale,
        borderRadius: 4,
      }}
    >
      <View
        style={{
          width: `${Math.round(percentage)}%`,
          borderRadius: 4,
          backgroundColor: colors.success,
          height: 18,
        }}
      />
    </View>
  );
}
