import { useState, useCallback } from "react";
import { MultiSelectField, useSelectQuery } from "@smartrent/ui";
import { PaginatedResponse } from "@smartrent/hooks";
import { StyleSheet } from "react-native";

import { instance } from "@/lib/hooks";

type DropdownData = { id: string } & Record<string, any>;

interface Props {
  onChange: (selectedRecords: DropdownData[]) => void;
  style?: any;
}

export function OrganizationMultiSelect({ onChange, style }: Props) {
  const [values, setValues] = useState<DropdownData[]>([]);
  const handleChange = useCallback(
    (values: any) => {
      setValues(values);
      onChange(values);
    },
    [setValues, onChange]
  );

  const selectQueryProps = useSelectQuery(
    (inputValue) => ["organizations", { inputValue }] as const,
    function fetchOrganizations({ queryKey, pageParam = 1 }) {
      const [, { inputValue }] = queryKey;
      return instance
        .get(`/organizations`, {
          params: {
            name: inputValue ? inputValue : undefined,
            page: pageParam,
          },
        })
        .then((response) => response.data as PaginatedResponse<DropdownData>);
    },
    {},
    {
      inputDebounceInterval: 250,
    }
  );

  return (
    <MultiSelectField<DropdownData>
      style={[styles.filter, style]}
      value={values}
      onChange={handleChange}
      name="organizations"
      label="Select Organizations"
      getOptionValue={({ id }) => id}
      getOptionLabel={({ name }) => name}
      {...selectQueryProps}
    />
  );
}

const styles = StyleSheet.create({
  filter: {
    maxWidth: 450,
  },
});
