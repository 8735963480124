import { startCase } from "lodash-es";

import { useSelectQuery } from "@smartrent/ui";
import { PaginatedResponse } from "@smartrent/hooks";

import { instance } from "@/lib/hooks";
import { isValidSfId } from "@/utils";

import {
  SalesForceAccount,
  SalesForceOpportunity,
  SalesForceProperty,
  SalesforceDevice,
} from "@/types";
import { createAxiosQuery } from "@/hooks/react-query";

export const useSalesforceAccount = createAxiosQuery(
  "salesforce-account",
  (account_id) =>
    instance.get<PaginatedResponse<SalesForceAccount>>(`/salesforce/accounts`, {
      params: {
        Id: account_id,
        limit: 1,
      },
    })
);

export const useSalesforceProperty = createAxiosQuery(
  "salesforce-property",
  (property_id) =>
    instance.get<PaginatedResponse<SalesForceProperty>>(
      `/salesforce/properties`,
      {
        params: {
          Id: property_id,
          limit: 1,
        },
      }
    )
);

export const useSalesforcePotentialProperty = createAxiosQuery(
  "salesforce-property-potentials",
  ({ name, address }) => {
    return instance.get<PaginatedResponse<SalesForceProperty>>(
      `/salesforce/potential-properties`,
      {
        params: {
          name: name,
          address: address,
        },
      }
    );
  }
);

export const useSalesforcePotentialAccount = createAxiosQuery(
  "salesforce-property-potentials",
  ({ name, address }) => {
    return instance.get<PaginatedResponse<SalesForceProperty>>(
      `/salesforce/potential-accounts`,
      {
        params: {
          name: name,
        },
      }
    );
  }
);

export const selectQueryPropsSalesforceOpportunities = () =>
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useSelectQuery<PaginatedResponse<SalesForceOpportunity>>(
    (inputValue) => ["salesforce-opportunities", inputValue] as const,
    function fetch({ queryKey, pageParam = 1 }) {
      const [, inputValue] = queryKey;
      return instance
        .get(`/salesforce/opportunities`, {
          params: {
            name: inputValue ? inputValue : undefined,
            page: pageParam,
            limit: 150,
          },
        })
        .then((response) => response.data);
    },
    {},
    {
      inputDebounceInterval: 250,
    }
  );

export const selectQueryPropsSalesforceOpportunityProperties = (
  opportunity_id?: string
) =>
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useSelectQuery<PaginatedResponse<SalesForceOpportunity>>(
    (inputValue) =>
      ["salesforce-properties", opportunity_id, inputValue] as const,
    function fetch({ queryKey, pageParam = 1 }) {
      const [, , inputValue] = queryKey;

      return instance
        .get(`/salesforce/opportunity-properties`, {
          params: {
            name: inputValue ? inputValue : undefined,
            opportunity_id: opportunity_id ? opportunity_id : undefined,
            page: pageParam,
            limit: 150,
          },
        })
        .then((response) => response.data);
    },
    { enabled: opportunity_id ? true : false },
    {
      inputDebounceInterval: 250,
    }
  );

export const selectQueryPropsSalesforceProperties = () =>
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useSelectQuery<PaginatedResponse<SalesForceProperty>>(
    (inputValue) => ["salesforce-properties", inputValue] as const,
    function fetch({ queryKey, pageParam = 1 }) {
      const [, inputValue] = queryKey;

      return instance
        .get(`/salesforce/properties`, {
          params: {
            name: !isValidSfId(inputValue) ? inputValue : undefined,
            Id: isValidSfId(inputValue) ? inputValue : undefined,
            page: pageParam,
            limit: 150,
          },
        })
        .then((response) => response.data);
    },
    {},
    {
      inputDebounceInterval: 250,
    }
  );

export const selectQueryPropsSalesforceAccounts = () =>
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useSelectQuery<PaginatedResponse<SalesForceAccount>>(
    (inputValue) => ["salesforce-accounts", inputValue] as const,
    function fetch({ queryKey, pageParam = 1 }) {
      const [, inputValue] = queryKey;

      return instance
        .get(`/salesforce/accounts`, {
          params: {
            name: !isValidSfId(inputValue) ? inputValue : undefined,
            Id: isValidSfId(inputValue) ? inputValue : undefined,
            page: pageParam,
            limit: 150,
          },
        })
        .then((response) => response.data);
    },
    {},
    {
      inputDebounceInterval: 250,
    }
  );

export const selectQueryPropsSalesforceDevices = () =>
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useSelectQuery(
    (inputValue) => ["salesforce-devices", { inputValue }] as const,
    function fetch({ queryKey, pageParam = 1 }) {
      const [, { inputValue }] = queryKey;
      return instance
        .get(`/salesforce/devices`, {
          params: {
            term: inputValue ? inputValue : undefined,
            category: startCase(window.location.href.split("add-")[1]),
            page: pageParam,
            limit: 25,
          },
        })
        .then(
          (response) => response.data as PaginatedResponse<SalesforceDevice>
        );
    },
    {},
    {
      inputDebounceInterval: 250,
    }
  );
