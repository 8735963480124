import { useEffect } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { AlertCircle } from "lucide-react";

import { useForm } from "react-hook-form";

import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/_v2/components/ui/dialog";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/_v2/components/ui/form";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/_v2/components/ui/select";
import { Input } from "@/_v2/components/ui/input";
import { Button } from "@/_v2/components/ui/button";
import { Textarea } from "@/_v2/components/ui/textarea";
import { Alert, AlertDescription } from "@/_v2/components/ui/alert";

import { useFeedbackCollectorMutation } from "@/api";

export interface FeedbackCollectorBody {
  title: string;
  description: string;
  issue_type: string;
}

interface FeedbackModalProps {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}
export const FeedbackModal = ({ visible, setVisible }: FeedbackModalProps) => {
  const [submitFeedbackCollector, { isError }] = useFeedbackCollectorMutation();

  const formSchema = z.object({
    issue_type: z.string().min(1, {
      message: "Please select an issue type.",
    }),
    title: z.string().min(1, {
      message: "Please add a title.",
    }),
    description: z.string().min(10, {
      message: "Description must be at least 10 characters.",
    }),
    component: z.string().min(1, {
      message: "Please select a component.",
    }),
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      issue_type: "Story",
      title: "",
      description: "",
      component: "",
    },
  });

  const onChange = () => {
    setVisible(!visible);
    form.reset();
  };

  async function onSubmit(values: FeedbackCollectorBody) {
    await submitFeedbackCollector(values);
    setVisible(false);
  }

  useEffect(() => {
    form.reset();
  }, [setVisible, visible, form]);

  return (
    <Dialog open={visible} onOpenChange={onChange}>
      <DialogContent className="sm:max-w-md w-[95%] m-0">
        <DialogHeader>
          <DialogTitle>Submit Feedback</DialogTitle>
        </DialogHeader>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
            <FormField
              control={form.control}
              name="issue_type"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>
                    Issue Type <span className="text-destructive">*</span>
                  </FormLabel>
                  <Select
                    onValueChange={field.onChange}
                    defaultValue={field.value}
                  >
                    <FormControl>
                      <SelectTrigger>
                        <SelectValue placeholder="Pick from the list" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      <SelectItem value="Story">Feature Request</SelectItem>
                      <SelectItem value="Bug">Bug</SelectItem>
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="title"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>
                    Issue Title <span className="text-destructive">*</span>
                  </FormLabel>
                  <FormControl>
                    <Input placeholder="Title" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="description"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>
                    Description <span className="text-destructive">*</span>
                  </FormLabel>
                  <FormControl>
                    <Textarea
                      placeholder="Describe the issue"
                      className="resize-none h-32"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="component"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>
                    Component <span className="text-destructive">*</span>
                  </FormLabel>
                  <Select
                    onValueChange={field.onChange}
                    defaultValue={field.value}
                  >
                    <FormControl>
                      <SelectTrigger>
                        <SelectValue placeholder="Pick from the list" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      <SelectItem value="TOURS">Tours (TOURS)</SelectItem>
                      <SelectItem value="SUPP">SupportRent (SUPP)</SelectItem>
                      <SelectItem value="RES">Resident (RES)</SelectItem>
                      <SelectItem value="PASS">Alloy Pass (PASS)</SelectItem>
                      <SelectItem value="PARK">Parking (PARK)</SelectItem>
                      <SelectItem value="INSTALL">
                        Alloy Install (INSTALL)
                      </SelectItem>
                      <SelectItem value="CMW">CMW (CMW)</SelectItem>
                      <SelectItem value="CMM">CMM (CMM)</SelectItem>
                      <SelectItem value="AA">Alloy Access (AA)</SelectItem>
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />
            {isError ? (
              <Alert variant="destructive">
                <AlertCircle className="h-4 w-4" />
                <AlertDescription>
                  Something went wrong submitting your feedback. Please try
                  again.
                </AlertDescription>
              </Alert>
            ) : null}
            <DialogFooter className="sm:justify-end">
              <DialogClose asChild>
                <Button type="button" variant="secondary">
                  Cancel
                </Button>
              </DialogClose>
              <Button type="submit">Submit</Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};
