import { cn } from "@/_v2/utils";
import { useCopyText } from "@/_v2/utils/hooks/use-copy-text";
import { useDebouncedValue } from "@/_v2/utils/hooks/use-debounced-value";

import { Tooltip, TooltipTrigger, TooltipContent } from "../ui/tooltip";

interface CopyValueProps {
  value?: string | number;
  className?: string;
  children?: React.ReactNode;
  tooltipProps?: React.ComponentProps<typeof Tooltip>;
  tooltipContentProps?: React.ComponentProps<typeof TooltipContent>;
}

/**
 * UI component that copies a value to the clipboard when clicked.
 * Use this for displaying values that users might want to copy.
 *
 * It will show a tooltip with "Copied!" when the value is copied.
 */
export const CopyValue: React.FC<CopyValueProps> = ({
  value,
  className,
  children,
  tooltipProps,
  tooltipContentProps,
}) => {
  const { copyText, successState } = useCopyText();

  // Show "Copied!" for a short time after copying to allow the tooltip to finish animating
  const delayedSuccessState = useDebouncedValue(successState, 150);

  return (
    <Tooltip
      open={successState === true ? true : undefined}
      delayDuration={1000}
      {...tooltipProps}
    >
      <TooltipTrigger>
        <div
          className={cn(
            "cursor-pointer p-1 rounded-sm hover:bg-muted transition-colors",
            className
          )}
          onClick={() => copyText(`${value}`)}
        >
          {children ? children : value}
        </div>
      </TooltipTrigger>
      <TooltipContent align="center" {...tooltipContentProps}>
        {successState || delayedSuccessState ? "Copied!" : "Click to copy"}
      </TooltipContent>
    </Tooltip>
  );
};
