import { CircleBackslashIcon } from "@radix-ui/react-icons";

import { useCurrentUnit } from "@/_v2/api/units";

import { Skeleton } from "@/_v2/components/ui/skeleton";
import { UnitPageResponseProps } from "@/_v2/types/unit";

export function UnitImage() {
  const { data, isFetching } = useCurrentUnit();

  if (!data || isFetching) {
    return <Skeleton className="h-[46px] aspect-[4/3] rounded-md" />;
  }

  const { unit, group, organization } = data as UnitPageResponseProps;
  const preferredImage = getPreferredImage({ unit, group, organization });

  if (!preferredImage) {
    return (
      <div className="h-[46px] aspect-[4/3] rounded-md bg-muted flex justify-center items-center">
        <CircleBackslashIcon className="w-5 h-5 text-muted-foreground m-auto" />
      </div>
    );
  }

  return (
    <div className="h-[46px] aspect-[4/3] relative bg-slate-100 rounded-md overflow-hidden">
      <img
        src={`${preferredImage}?fit=clamp&max-h=48&w=72`}
        alt={data?.unit.marketing_name || "unit"}
        className="object-cover"
      />
    </div>
  );
}

function getPreferredImage({
  unit,
  group,
  organization,
}: {
  unit: UnitPageResponseProps["unit"];
  group: UnitPageResponseProps["group"];
  organization: UnitPageResponseProps["organization"];
}) {
  if (unit.image_url) {
    return unit.image_url;
  } else if (group.group_image) {
    return group.group_image;
  } else if (organization.logo_url) {
    return organization.logo_url;
  }
  return null;
}
