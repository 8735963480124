import { DateRangePickerField } from "@smartrent/forms";

import { OrganizationMultiSelect } from "@/common/filters/OrganizationMultiSelect";
import { GroupMultiSelect } from "@/common/filters/GroupMultiSelect";
import { OrganizationProps } from "@/types";

import { useDashboardFilterStore } from "./dashboards-filter-state";

export const DashboardsFilters = () => {
  const { filters, activeFilterStatuses, setFilters } = useDashboardFilterStore(
    ({ filters, activeFilterStatuses, setFilters }) => ({
      filters,
      activeFilterStatuses,
      setFilters,
    })
  );
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        gap: 16,
        alignItems: "stretch",
      }}
    >
      <OrganizationMultiSelect
        style={{ flex: 1 }}
        onChange={(organizations: OrganizationProps[]) =>
          setFilters({ ...filters, organizations })
        }
      />

      <GroupMultiSelect
        style={{ flex: 1 }}
        onChange={(groups: any[]) => setFilters({ ...filters, groups })}
        filters={{
          organization_ids:
            filters.organizations && filters.organizations.length > 0
              ? filters.organizations?.map((o: OrganizationProps) => o.id)
              : undefined,
        }}
      />

      {activeFilterStatuses?.dateRange !== false ? (
        <DateRangePickerField
          value={filters.dateRange}
          onChange={(dateRange) => setFilters({ ...filters, dateRange })}
          label="Select Date Range"
        />
      ) : null}
    </div>
  );
};
