import { useContext } from "react";
import { Bar } from "react-chartjs-2";

import { View, Text } from "react-native";

import Graph from "@/common/Graph";
import HoverText from "@/common/HoverText";
import Context from "@/layout/Context";
import Helpers from "@/lib/helpers";

export default function TourFunnel({ filters }: { filters?: string }) {
  const { isDesktop } = useContext(Context);

  return (
    <Graph
      path="/reports/tours"
      pathSearch={filters}
      dataKey="tours"
      leftFooterRenderProps={({ tourResidentConversion, tourCount }) => {
        return (
          <div>
            <div>
              <span className="u-sans-bold">Total Tours</span>:{" "}
              {(
                Number(tourCount.finalized_tours) +
                Number(tourCount.unfinalized_tours)
              ).toLocaleString()}
            </div>
            <div>
              <span className="u-sans-bold">Total Resident Conversions</span>:{" "}
              {Number(tourResidentConversion.count).toLocaleString()}
            </div>
          </div>
        );
      }}
      renderProps={({ tourFunnel }) => {
        const { createdByCMW, createdBySelfTours } = tourFunnel;
        return (
          <View>
            {isDesktop && (
              <View style={{ alignItems: "center" }}>
                <View
                  style={{
                    flexDirection: "row",
                  }}
                >
                  <Text style={{ fontWeight: "600", marginRight: 8 }}>
                    Tour Funnel
                  </Text>
                  <HoverText
                    color="black"
                    tooltipText="Gathers a % of tours for which the given step was completed"
                    tooltipDirection="right"
                    width={200}
                  />
                </View>
                <Bar
                  height={50}
                  data={{
                    labels: [
                      "Home Selection",
                      "Schedule Page",
                      "Verify Phone Number",
                      "Contact Information",
                      "Front ID",
                      "Back ID",
                      "Selfie",
                      "Finalized Tour",
                      "Viewed Access Code",
                      "Clicked Application Link",
                    ],
                    datasets: [
                      {
                        data: createdByCMW,
                        label: "Created in CMW",
                        backgroundColor: Helpers.lineGraphColors.darkGreen,
                        borderColor: Helpers.lineGraphColors.darkGreen,
                        pointBorderColor: Helpers.lineGraphColors.darkGreen,
                        pointHoverBackgroundColor:
                          Helpers.lineGraphColors.darkGreen,
                        pointHoverBorderColor:
                          Helpers.lineGraphColors.darkGreen,
                      },
                      {
                        data: createdBySelfTours,
                        label: "Selftournow.com",
                        backgroundColor: Helpers.lineGraphColors.lightGreen,
                        borderColor: Helpers.lineGraphColors.lightGreen,
                        pointBorderColor: Helpers.lineGraphColors.lightGreen,
                        pointHoverBackgroundColor:
                          Helpers.lineGraphColors.lightGreen,
                        pointHoverBorderColor:
                          Helpers.lineGraphColors.lightGreen,
                      },
                    ],
                  }}
                  options={{
                    barValueSpacing: 20,
                    scales: {
                      yAxes: [
                        {
                          ticks: {
                            min: 0,
                            max: 100,
                            stepSize: 25,
                            callback: function (value) {
                              return value + "%";
                            },
                          },
                          scaleLabel: {
                            display: true,
                            labelString: "Percentage",
                          },
                        },
                      ],
                    },
                    title: {
                      display: false,
                    },
                    tooltips: {
                      callbacks: {
                        label: function (tooltipItem) {
                          const label =
                            `Completed ${tooltipItem.xLabel}: ` +
                            tooltipItem.yLabel +
                            "%";

                          return label;
                        },
                      },
                      title: null,
                    },
                    legend: {
                      display: true,
                    },
                  }}
                />
              </View>
            )}
          </View>
        );
      }}
    />
  );
}
