import * as React from "react";
import { Check, ChevronsUpDown } from "lucide-react";

import { cn } from "@/_v2/utils";
import { Button } from "@/_v2/components/ui/button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/_v2/components/ui/command";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/_v2/components/ui/popover";

export function Combobox({
  options,
  placeholder,
  label,
  value,
  setValue,
}: {
  options: any[];
  placeholder: string;
  label: string;
  value: any;
  setValue: (value: any) => void;
}) {
  const [open, setOpen] = React.useState(false);

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className="w-[200px] justify-between"
        >
          {value
            ? options.find((option) => option.id == value)?.[label]
            : `Select ${placeholder}`}
          <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[200px] p-0">
        <Command>
          <CommandInput placeholder={`Search ${placeholder}s`} />
          <CommandList>
            <CommandEmpty>No {placeholder} found.</CommandEmpty>
            <CommandGroup>
              {options?.map((type) => (
                <CommandItem
                  key={type[label]}
                  value={type[value]}
                  onSelect={(currentValue) => {
                    setValue(parseInt(type.id));
                    setOpen(false);
                  }}
                >
                  <Check
                    className={cn(
                      "mr-2 h-4 w-4",
                      value == type.id ? "opacity-100" : "opacity-0"
                    )}
                  />
                  {type[label]}
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
}
