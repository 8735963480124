import { useState } from "react";
import { toast } from "sonner";

import { Button } from "@/_v2/components/ui/button";
import { Input } from "@/_v2/components/ui/input";
import { Badge } from "@/_v2/components/ui/badge";
import { Card, CardContent } from "@/_v2/components/ui/card";
import { Skeleton } from "@/_v2/components/ui/skeleton";

import {
  useSalesforcePotentialAccount,
  useUpdateOrganizationMutation,
} from "@/api";
import { OrganizationProps } from "@/types";
import { isValidSfId } from "@/utils";

import { NoMatchesFound } from "./NoMatchesFound";

interface OrganizationComponentProps {
  organization: OrganizationProps;
}

export function Organization({ organization }: OrganizationComponentProps) {
  const [salesforceId, setSalesforceId] = useState<string>("");

  const { data: sf_link, isLoading } = useSalesforcePotentialAccount({
    name: organization?.name ?? "",
  });

  const [updateOrg, { isLoading: updatingOrg }] = useUpdateOrganizationMutation(
    {
      onSuccess: (queryClient) => {
        toast.success("Organization updated", {
          description: "Organization has been updated successfully.",
          className: "px-8 py-4",
        });
        queryClient.invalidateQueries({
          queryKey: ["get-organizations"],
        });
        queryClient.invalidateQueries({
          queryKey: ["org-and-properties-metrics"],
        });
      },
    }
  );

  const handleSalesforceIdChange = (value: string): void => {
    setSalesforceId(value);
  };

  const getMatchBadgeColor = (matchPercentage: number): string => {
    if (matchPercentage >= 90) {
      return "bg-green-500 text-white";
    }
    if (matchPercentage >= 70) {
      return "bg-yellow-500 text-black";
    }
    return "bg-red-500 text-white";
  };

  return (
    <Card key={organization?.id}>
      <CardContent className="p-4">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
          <div className="ml-4">
            <h3 className="font-semibold">{organization?.name}</h3>
            {organization && (
              <p className="text-sm text-muted-foreground">
                {organization?.street_address_1
                  ? `${organization?.street_address_1}${organization?.street_address_2 ? ` ${organization?.street_address_2}` : ""}, ${organization?.city}, ${organization?.state} ${organization?.zip}`
                  : null}
              </p>
            )}
          </div>
          <div>
            <h4 className="font-semibold mb-2">Salesforce Account</h4>
            <div className="flex items-center">
              <Input
                type="text"
                placeholder="Enter Salesforce ID"
                value={salesforceId || ""}
                onChange={(e) => handleSalesforceIdChange(e.target.value)}
                className="mr-2"
              />
              <Button
                variant="outline"
                size="sm"
                onClick={() => {
                  updateOrg({
                    id: organization?.id,
                    salesforce_id: salesforceId,
                  });
                }}
                disabled={!isValidSfId(salesforceId) || updatingOrg}
                className="mr-4"
              >
                Update
              </Button>
            </div>
            {salesforceId && !isValidSfId(salesforceId) && (
              <Badge className="mt-2 bg-red-500 text-white">Invalid ID</Badge>
            )}
          </div>
        </div>
        <div>
          <div className="flex items-center mb-2">
            <h4 className="font-semibold">Salesforce Potentials</h4>
          </div>
          <div className="space-y-2">
            {isLoading ? (
              <div className="flex flex-col space-y-3">
                <Skeleton className="h-[125px] w-full rounded-xl" />
                <div className="space-y-2">
                  <Skeleton className="h-4 w-[250px]" />
                  <Skeleton className="h-4 w-[200px]" />
                </div>
              </div>
            ) : null}
            {sf_link?.records.length === 0 ? (
              <NoMatchesFound />
            ) : (
              sf_link?.records
                ?.sort((a, b) => {
                  const aMatch = a.nameMatchPercentage;
                  const bMatch = b.nameMatchPercentage;
                  return bMatch - aMatch; // Descending order
                })
                .map((match: any) => {
                  return (
                    <div
                      key={match.Id}
                      className={
                        match.Id === salesforceId
                          ? "border rounded p-2 bg-green-100"
                          : match.nameMatchPercentage === 100
                            ? "border rounded p-2 bg-blue-100"
                            : "border rounded p-2 opacity-50 hover:opacity-100 transition"
                      }
                    >
                      <div className="flex justify-between items-start mb-2">
                        <div>
                          <div className="flex items-center">
                            <p className="font-medium mr-2">{match.Name}</p>
                            <Badge
                              className={`mr-2 ${getMatchBadgeColor(match.nameMatchPercentage)}`}
                            >
                              {match.nameMatchPercentage}% match
                            </Badge>
                            {match.nameMatchPercentage === 100 && (
                              <Badge className="ml-2 bg-blue-500 text-white">
                                Suggested Match
                              </Badge>
                            )}
                          </div>
                          <div className="flex items-center ">
                            <p className="font-medium mr-2">Salesforce ID:</p>
                            <p className="text-sm text-muted-foreground mr-2">
                              {match.Id}
                            </p>
                            <Button
                              variant="outline"
                              size="sm"
                              onClick={() => handleSalesforceIdChange(match.Id)}
                            >
                              {match.Id === salesforceId
                                ? "Selected"
                                : "Select"}
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
            )}
          </div>
        </div>
      </CardContent>
    </Card>
  );
}
