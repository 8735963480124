import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

import {
  ActionSheetProvider,
  PlatformProvider,
  ThemeMode,
  ThemeProvider,
} from "@smartrent/ui";

import { ThemeProvider as ShadThemeProvider } from "@/_v2/components/theme-provider";

import { QueryClientProvider } from "@/api/query-client";
import { DialogProvider } from "@/lib/contexts/dialog";

import { Router } from "./Router";
import { TooltipProvider } from "./_v2/components/ui/tooltip";
import { Toaster } from "./_v2/components/ui/sonner";

import "./main.css";

import "@/lib/sentry";
import "@/utils/favicon";

const storageKey = "ui-theme";

const root = createRoot(document.getElementById("root"));

root.render(
  <ShadThemeProvider storageKey={storageKey}>
    {/* this is using the shad theme provider key */}
    <Toaster />
    <ThemeProvider
      initialMode={(localStorage.getItem(storageKey) as ThemeMode) || "light"}
    >
      <QueryClientProvider>
        {import.meta.env.REACT_APP_REACT_QUERY_DEV_TOOLS ? (
          <ReactQueryDevtools initialIsOpen={false} />
        ) : null}
        <PlatformProvider>
          <BrowserRouter>
            <DialogProvider>
              <ActionSheetProvider>
                <TooltipProvider delayDuration={350}>
                  <Router />
                </TooltipProvider>
              </ActionSheetProvider>
            </DialogProvider>
          </BrowserRouter>
        </PlatformProvider>
      </QueryClientProvider>
    </ThemeProvider>
  </ShadThemeProvider>
);
