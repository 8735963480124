import { useParams } from "react-router-dom";

import { instance } from "@/lib/hooks";
import { createAxiosQuery } from "@/hooks/react-query";
import { tableQueryToQS } from "@/lib/helpers";

import { UnitPageResponseProps, UnitProps } from "@/_v2/types/unit";

import { TppViewProps } from "@/types";

import { PaginatedResponse, TableQuery } from "../types/api";

export type UnitResponse = Pick<
  UnitProps,
  "id" | "marketing_name" | "status" | "building" | "floor"
>;
export interface UnitsResponseProps extends PaginatedResponse<UnitResponse> {}

export const useUnitsQuery = createAxiosQuery(
  "units",
  async (filters?: TableQuery) => {
    const query = tableQueryToQS(filters);

    return instance.get<UnitsResponseProps>(`/v2/units?${query}`);
  }
);

export const useCurrentUnit = () => {
  const { unitId } = useParams<{ unitId: string }>();

  return useUnitPageQuery({ unitId });
};

export const useUnitPageQuery = createAxiosQuery(
  "unit",
  async (filters?: { unitId: string }) => {
    return instance.get<UnitPageResponseProps>(`/v2/units/${filters?.unitId}`);
  }
);

export interface UnitDetailsResponseProps {
  id: string;
  community_wifi_enabled?: boolean;
  has_zwave_locks: boolean;
  has_non_zwave_locks: boolean;
  access_control_provider_names: string[];

  // property lock settings
  lock_vacant_units?: boolean;
  lock_audio?: string;
  privacy_lock?: boolean;
  auto_lock_timeout?: boolean;
  battery_threshold?: number;

  // tours
  tourable: boolean;
  visible_on_selftournow: boolean;
  group_tour_uuid?: string;
}

export const useCurrentUnitDetails = () => {
  const { unitId } = useParams<{ unitId: string }>();

  return useUnitDetailsQuery({ unitId });
};

export const useUnitDetailsQuery = createAxiosQuery(
  "unit-details",
  async (filters?: { unitId: string }) => {
    return instance.get<UnitDetailsResponseProps>(
      `/v2/units/${filters?.unitId}/details`
    );
  }
);

export interface UnitNotesResponseProps {
  id: string;
  body: string;
  inserted_at: string;
  first_name: string;
  last_name: string;
  email: string;
}

export const useCurrentUnitNotes = () => {
  const { unitId } = useParams<{ unitId: string }>();

  return useUnitNotesQuery({ unitId });
};

export const useUnitNotesQuery = createAxiosQuery(
  "unit-notes",
  async (filters?: { unitId: string }) => {
    return instance.get<UnitNotesResponseProps[]>(
      `/v2/units/${filters?.unitId}/notes`
    );
  }
);

export const useCurrentUnitIntegrations = () => {
  const { unitId } = useParams<{ unitId: string }>();

  return useUnitIntegrationsQuery({ unitId });
};

export interface UnitIntegrationsResponseProps {
  groups: TppViewProps[];
  organizations: TppViewProps[];
}

export const useUnitIntegrationsQuery = createAxiosQuery(
  "unit-integrations",
  async (filters?: { unitId: string }) => {
    return instance.get<UnitIntegrationsResponseProps>(
      `/v2/units/${filters?.unitId}/integrations`
    );
  }
);
