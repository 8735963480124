/* eslint-disable react-hooks/rules-of-hooks */
import { useState } from "react";
import { ColumnDef } from "@tanstack/react-table";

import type { SRFeatureFlagsProps } from "@shared-types/supportrent";

import { Switch } from "@/_v2/components/ui/switch";

import { useUser } from "@/layout/Context";
import { useSRFeatureFlagMutation } from "@/_v2/api/sr-feature-flags";

export const columns: ColumnDef<SRFeatureFlagsProps>[] = [
  {
    accessorKey: "id",
    header: "Id",
    enableSorting: false,
  },
  {
    accessorKey: "name",
    header: "Name",
    enableSorting: false,
  },
  {
    accessorKey: "description",
    header: "Description",
    enableSorting: false,
  },
  {
    accessorKey: "inserted_at",
    header: "Created At",
    size: 50,
    maxSize: 200,
    enableSorting: false,
  },
  {
    accessorKey: "updated_at",
    header: "Updated At",
    size: 50,
    maxSize: 200,
    enableSorting: false,
  },
  {
    accessorKey: "enabled",
    header: "Enabled",
    size: 50,
    maxSize: 200,
    enableSorting: false,
    cell: ({ row }) => {
      const [loading, setLoading] = useState(false);
      const { permissions } = useUser();
      const [updateFlag] = useSRFeatureFlagMutation();
      return (
        <Switch
          checked={row.original.enabled}
          onCheckedChange={async (value) => {
            setLoading(true);
            try {
              await updateFlag({ name: row.original.name, enabled: value });
            } finally {
              setLoading(false);
            }
          }}
          disabled={loading || !permissions.sr_feature_flags_manage}
          aria-label="Enable or disable feature flag"
        />
      );
    },
  },
];
