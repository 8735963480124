import { Card } from "@/_v2/components/ui/card";
import { useDocumentTitle } from "@/hooks/use-document-title";

import { UnitPageHeader } from "./components/unit-page-header";
import { UnitPageDetails } from "./components/unit-page-details/unit-page-details";

export const UnitPage = () => {
  useDocumentTitle("Unit Details", " | SupportRent V2");
  return (
    <div>
      <UnitPageHeader />

      <div className="flex flex-col lg:flex-row gap-2">
        <div className="lg:w-3/4 space-y-2">
          <UnitPageDetails />

          <div className="grid grid-cols-2 gap-2 px-3">
            {/* Hub Details */}
            <Card className="">Hub details</Card>

            {/* Warnings */}
            <Card>warnings</Card>
          </div>

          {/* Tables */}
          <Card />
          <Card />
          <Card />
        </div>

        {/* Right Side Content */}
        <div className="lg:w-1/4 space-y-2">
          <Card>Notes</Card>
          <Card>Notes</Card>
          <Card>Notes</Card>
          <Card>Notes</Card>
        </div>
      </div>
    </div>
  );
};
