import { useEffect } from "react";
import { Breadcrumbs, TabsNavigation, useTheme } from "@smartrent/ui";
import { FloorPlan, Hub, GraphSolid } from "@smartrent/icons";

import { useUser } from "@/layout/Context";
import { LayoutV2 } from "@/_v2/layout/layout";

import { DashboardsFilters } from "./DashboardsFilters";
import { useDashboardFilterStore } from "./dashboards-filter-state";

export const DashboardsLayout = ({ children }: React.PropsWithChildren) => {
  const { colors } = useTheme();
  const { permissions } = useUser();
  const { reset } = useDashboardFilterStore();

  useEffect(() => {
    // Reset filters on mount to avoid stale filters when navigating to this page again
    reset();
  }, [reset]);

  const tabs = [];

  if (permissions?.view_smartrent_client_dashboard) {
    tabs.push({
      id: "customer_insights",
      label: "Customer Insights",
      Icon: GraphSolid,
      to: "/dashboards/client",
    });
  }

  tabs.push(
    {
      id: "iot",
      label: "IoT",
      Icon: Hub,
      to: "/dashboards/iot",
    },
    {
      id: "tours",
      label: "Tours",
      Icon: FloorPlan,
      to: "/dashboards/tours",
    }
  );

  return (
    <LayoutV2>
      <div>
        <Breadcrumbs items={[{ label: "SmartRent Insights" }]} />

        <DashboardsFilters />

        <TabsNavigation
          align="flex-start"
          style={{
            paddingHorizontal: 0,
            borderBottomColor: colors.border,
            borderBottomWidth: 1,
          }}
          tabStyle={{ paddingTop: 0 }}
          items={tabs}
        />
        {children}
      </div>
    </LayoutV2>
  );
};
