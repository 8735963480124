import { AlertCircle } from "lucide-react";

import { Alert, AlertDescription, AlertTitle } from "@/_v2/components/ui/alert";

export function NoMatchesFound() {
  return (
    <Alert variant="default" className="mt-4">
      <AlertCircle className="h-4 w-4" />
      <AlertTitle>No Matches Found</AlertTitle>
      <AlertDescription className="mt-2">
        We couldn&apos;t find any potential Salesforce matches for this
        property. You can:
        <ul className="list-disc list-inside mt-2 space-y-1">
          <li>Double-check the property details</li>
          <li>Try adjusting your search criteria</li>
          <li>Manually link this property to a Salesforce record</li>
        </ul>
      </AlertDescription>
    </Alert>
  );
}
