import { Route, Switch } from "react-router-dom";
import "./main.css";

import { RequirePermission } from "@/common/RequirePermission";

import { LayoutV2 } from "./layout/layout";
import { SRFeatureFlagsPage } from "./pages/sr-feature-flags/SRFeatureFlagsPage";
import { SalesforceLinkReviewTool } from "./pages/salesforce-link-review-tool/SalesforceLinkReviewTool";
import { AccountsPage } from "./pages/accounts/AccountsPage";
import { UnitPage } from "./pages/unit/UnitPage";
import { ConversationSelectGroupPage } from "./pages/conversation/group/ConversationSelectGroupPage";
import { ConversationSelectUnitPage } from "./pages/conversation/unit/ConversationSelectUnitPage";

// import { HomePage } from "./pages/home/home-page";
// import { OrganizationsPage } from "./pages/organizations/OrganizationsPage";

export const RouterV2 = () => {
  return (
    <LayoutV2>
      <Switch>
        {/* <Route exact path="/v2" render={HomePage} />

        <Route exact path="/v2/organizations" render={OrganizationsPage} /> */}

        <Route exact path="/v2/units/:unitId" render={() => <UnitPage />} />

        <Route
          exact
          path="/v2/conversation/:conversationId/group"
          render={() => <ConversationSelectGroupPage />}
        />
        <Route
          exact
          path="/v2/conversation/:conversationId/unit"
          render={() => <ConversationSelectUnitPage />}
        />

        <Route
          exact
          path="/v2/accounts"
          render={() => (
            <RequirePermission permission="modify_permissions">
              <AccountsPage />
            </RequirePermission>
          )}
        />

        <Route
          exact
          path="/v2/sr-feature-flags"
          render={() => (
            <RequirePermission permission="sr_feature_flags_view">
              <SRFeatureFlagsPage />
            </RequirePermission>
          )}
        />

        <Route
          exact
          path="/v2/salesforce-mapping"
          render={() => (
            <RequirePermission permission="id_integration_manager">
              <SalesforceLinkReviewTool />
            </RequirePermission>
          )}
        />
      </Switch>
    </LayoutV2>
  );
};
