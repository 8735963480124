import { useState } from "react";

import { Input } from "@/_v2/components/ui/input";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "@/_v2/components/ui/tabs";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "@/_v2/components/ui/card";
import {
  Pagination,
  PaginationContent,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
  PaginationEllipsis,
} from "@/_v2/components/ui/pagination";
import { Combobox } from "@/_v2/components/ui/combobox";

import {
  useOrganizationsQuery,
  useAllGroupsQuery,
  useOrgAndGroupMetricQuery,
} from "@/api";

import { Property } from "./Property";
import { Organization } from "./Organization";

export function SalesforceLinkReviewTool() {
  const [selectedTab, setSelectedTab] = useState("properties");

  const [propertySearchTerm, setPropertySearchTerm] = useState("");
  const [organizationSearchTerm, setOrganizationSearchTerm] = useState("");
  const [selectedOrg, setSelectedOrg] = useState<string>(null);
  const [currentSetPropertyPage, setCurrentPropertyPage] = useState(1);
  const [currentSetOrganizationPage, setCurrentOrganizationPage] = useState(1);

  const { data: groupsByOrg } = useAllGroupsQuery(
    {
      organization_id: parseInt(selectedOrg),
      page: currentSetPropertyPage,
      missing_salesforce_id: true,
      active: true,
      limit: 10,
    },
    { enabled: !!selectedOrg }
  );

  const { data: allOrgs } = useOrganizationsQuery({
    filters: {
      limit: 1000,
    },
  });

  const { data: unlinkedOrgs } = useOrganizationsQuery({
    filters: {
      limit: 10,
      page: currentSetOrganizationPage,
      missing_salesforce_id: true,
    },
  });

  const { data: totalOrgsAndGroups } = useOrgAndGroupMetricQuery({});
  const {
    groups_with_salesforce_id,
    organizations_with_salesforce_id,
    total_groups,
    total_organizations,
  } = totalOrgsAndGroups || {};

  const totalPropertyPages = groupsByOrg?.total_pages || 1;
  const totalOrgPages = unlinkedOrgs?.total_pages || 1;

  return (
    <div className="container mx-auto px-4 py-8">
      <Card className="w-full max-w-6xl mx-auto">
        <CardHeader>
          <CardTitle>Salesforce ID Mapping</CardTitle>
        </CardHeader>

        {/* Search and Percentage section */}

        <div className="grid grid-cols-2 gap-4 mb-4 px-10">
          <Card>
            <CardContent className="p-4">
              <div className="text-sm font-medium">Properties Linked</div>
              <div className="text-2xl font-bold">
                {groups_with_salesforce_id} / {total_groups}
              </div>
              <div className="text-sm text-muted-foreground">
                {((groups_with_salesforce_id / total_groups) * 100).toFixed(1)}%
              </div>
            </CardContent>
          </Card>
          <Card>
            <CardContent className="p-4">
              <div className="text-sm font-medium">Organizations Linked</div>
              <div className="text-2xl font-bold">
                {organizations_with_salesforce_id} / {total_organizations}
              </div>
              <div className="text-sm text-muted-foreground">
                {(
                  (organizations_with_salesforce_id / total_organizations) *
                  100
                ).toFixed(1)}
                %
              </div>
            </CardContent>
          </Card>
        </div>

        {/* Tab and Linking Section */}

        <CardContent>
          <Tabs
            value={selectedTab}
            onValueChange={setSelectedTab}
            className="w-full"
          >
            <TabsList className="flex">
              <TabsTrigger value="properties" className="flex-1">
                Properties
              </TabsTrigger>
              <TabsTrigger value="organizations" className="flex-1">
                Organizations
              </TabsTrigger>
            </TabsList>
            <TabsContent value="properties">
              <div className="flex flex-col md:flex-row gap-4 mb-4  ">
                <div className="flex-grow relative">
                  <Input
                    type="text"
                    placeholder="Search current list of property names"
                    value={propertySearchTerm}
                    onChange={(e) => setPropertySearchTerm(e.target.value)}
                    className="pl-8"
                    disabled={!selectedOrg}
                  />
                  {/*  eslint-disable-next-line jsx-a11y/accessible-emoji */}
                  <span className="absolute left-2 top-2.5 text-muted-foreground">
                    🔍
                  </span>
                </div>
                <Combobox
                  options={allOrgs?.records || []}
                  placeholder="Org"
                  label="name"
                  value={selectedOrg}
                  setValue={setSelectedOrg}
                />
              </div>
              <div className="space-y-4">
                {!selectedOrg ? (
                  <div className="text-center italic text-gray-500">
                    Please select an organization to view properties.
                  </div>
                ) : (
                  <>
                    {propertySearchTerm ? (
                      groupsByOrg?.records
                        ?.filter((property) =>
                          property.marketing_name
                            .toLowerCase()
                            .includes(propertySearchTerm.toLowerCase())
                        )
                        .map((property) => {
                          return (
                            <Property key={property.id} property={property} />
                          );
                        })
                    ) : groupsByOrg?.records.length === 0 ? (
                      <div className="text-center italic text-gray-500">
                        No properties found.
                      </div>
                    ) : (
                      groupsByOrg?.records?.map((property) => {
                        return (
                          <Property key={property.id} property={property} />
                        );
                      })
                    )}
                    {!propertySearchTerm && (
                      <Pagination className="mt-4">
                        <PaginationContent>
                          {currentSetPropertyPage > 1 && (
                            <PaginationItem>
                              <PaginationPrevious
                                onClick={() =>
                                  setCurrentPropertyPage((prev) =>
                                    Math.max(prev - 1, 1)
                                  )
                                }
                              />
                            </PaginationItem>
                          )}
                          {[...Array(totalPropertyPages)].map((_, index) => {
                            const pageNumber = index + 1;
                            if (
                              pageNumber === 1 ||
                              pageNumber === totalPropertyPages ||
                              (pageNumber >= currentSetPropertyPage - 1 &&
                                pageNumber <= currentSetPropertyPage + 1)
                            ) {
                              return (
                                <PaginationItem key={index}>
                                  <PaginationLink
                                    onClick={() =>
                                      setCurrentPropertyPage(pageNumber)
                                    }
                                    isActive={
                                      currentSetPropertyPage === pageNumber
                                    }
                                  >
                                    {pageNumber}
                                  </PaginationLink>
                                </PaginationItem>
                              );
                            } else if (
                              (pageNumber === currentSetPropertyPage - 2 &&
                                pageNumber > 1) ||
                              (pageNumber === currentSetPropertyPage + 2 &&
                                pageNumber < totalPropertyPages)
                            ) {
                              return <PaginationEllipsis key={index} />;
                            }
                            return null;
                          })}
                          {currentSetPropertyPage < totalPropertyPages && (
                            <PaginationItem>
                              <PaginationNext
                                onClick={() =>
                                  setCurrentPropertyPage((prev) =>
                                    Math.min(prev + 1, totalPropertyPages)
                                  )
                                }
                              />
                            </PaginationItem>
                          )}
                        </PaginationContent>
                      </Pagination>
                    )}
                  </>
                )}
              </div>
            </TabsContent>
            <TabsContent value="organizations">
              <div className="flex flex-col md:flex-row gap-4 mb-4  ">
                <div className="flex-grow relative">
                  <Input
                    type="text"
                    placeholder="Search current list of organization names"
                    value={organizationSearchTerm}
                    onChange={(e) => setOrganizationSearchTerm(e.target.value)}
                    className="pl-8"
                  />
                  {/*  eslint-disable-next-line jsx-a11y/accessible-emoji */}
                  <span className="absolute left-2 top-2.5 text-muted-foreground">
                    🔍
                  </span>
                </div>
              </div>
              <div className="space-y-4">
                {organizationSearchTerm
                  ? unlinkedOrgs?.records
                      ?.filter((organization) =>
                        organization.name
                          .toLowerCase()
                          .includes(organizationSearchTerm.toLowerCase())
                      )
                      .map((organization) => {
                        return (
                          <Organization
                            key={organization.id}
                            organization={organization}
                          />
                        );
                      })
                  : unlinkedOrgs?.records?.map((organization) => {
                      return (
                        <Organization
                          key={organization.id}
                          organization={organization}
                        />
                      );
                    })}
                {!organizationSearchTerm && (
                  <Pagination className="mt-4">
                    <PaginationContent>
                      {currentSetOrganizationPage > 1 && (
                        <PaginationItem>
                          <PaginationPrevious
                            onClick={() =>
                              setCurrentOrganizationPage((prev) =>
                                Math.max(prev - 1, 1)
                              )
                            }
                          />
                        </PaginationItem>
                      )}
                      {[...Array(totalOrgPages)].map((_, index) => {
                        const pageNumber = index + 1;
                        if (
                          pageNumber === 1 ||
                          pageNumber === totalOrgPages ||
                          (pageNumber >= currentSetOrganizationPage - 1 &&
                            pageNumber <= currentSetOrganizationPage + 1)
                        ) {
                          return (
                            <PaginationItem key={index}>
                              <PaginationLink
                                onClick={() =>
                                  setCurrentOrganizationPage(pageNumber)
                                }
                                isActive={
                                  currentSetOrganizationPage === pageNumber
                                }
                              >
                                {pageNumber}
                              </PaginationLink>
                            </PaginationItem>
                          );
                        } else if (
                          (pageNumber === currentSetOrganizationPage - 2 &&
                            pageNumber > 1) ||
                          (pageNumber === currentSetOrganizationPage + 2 &&
                            pageNumber < totalOrgPages)
                        ) {
                          return <PaginationEllipsis key={index} />;
                        }
                        return null;
                      })}
                      {currentSetOrganizationPage < totalOrgPages && (
                        <PaginationItem>
                          <PaginationNext
                            onClick={() =>
                              setCurrentOrganizationPage((prev) =>
                                Math.min(prev + 1, totalOrgPages)
                              )
                            }
                          />
                        </PaginationItem>
                      )}
                    </PaginationContent>
                  </Pagination>
                )}
              </div>
            </TabsContent>
          </Tabs>
        </CardContent>
      </Card>
    </div>
  );
}
