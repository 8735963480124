import { useCallback, useState } from "react";

export const useCopyText = () => {
  const [successState, setSuccessState] = useState<boolean>(false);

  const copyText = useCallback<(text?: string) => void>(async (text) => {
    if (!text) {
      return;
    }

    try {
      await navigator.clipboard.writeText(text);
      setSuccessState(true);
      setTimeout(() => {
        setSuccessState(false);
      }, 2000);
    } catch (e) {
      alert("Failed to copy text. You might try updating your browser.");
    }
  }, []);

  return { copyText, successState };
};
