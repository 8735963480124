import { Link } from "react-router-dom";
import { capitalize, isBoolean } from "lodash-es";
import { ExternalLinkIcon } from "lucide-react";

import {
  useCurrentUnit,
  useCurrentUnitDetails,
  useCurrentUnitIntegrations,
  useCurrentUnitNotes,
} from "@/_v2/api/units";
import { useGroupMaintenanceConfig } from "@/_v2/api/groups";

import {
  SideDrawer,
  SideDrawerClose,
} from "@/_v2/components/composites/side-drawer/side-drawer";
import { Button } from "@/_v2/components/ui/button";
import { cn } from "@/_v2/utils";
import { Badge } from "@/_v2/components/ui/badge";
import { links } from "@/_v2/utils/links";
import { groupsTourUrl, homeTourUrl } from "@/utils/links";
import { formatDate } from "@/utils/date";
import { useUser } from "@/layout/Context";

export const UnitPageDetailsDrawer = () => {
  const { permissions } = useUser();

  const { data: { unit, group } = {} } = useCurrentUnit();
  const { data: unitDetails } = useCurrentUnitDetails();
  const { data: unitNotes } = useCurrentUnitNotes();
  const { data: unitIntegrations } = useCurrentUnitIntegrations();
  const { data: unitMaintenanceConfig } = useGroupMaintenanceConfig(
    {
      groupId: group?.id,
    },
    { enabled: permissions.resident_details && !!group?.id }
  );

  const notify_resident_config =
    unitMaintenanceConfig?.maintenanceConfig?.notify_resident_config;

  return (
    <SideDrawer
      title={unit?.marketing_name || "Unit Details"}
      description="View more details about this unit"
      trigger={
        <Button variant="secondary" className="h-full rounded-lg">
          Details
        </Button>
      }
      footer={
        <div className="p-2">
          <SideDrawerClose />
        </div>
      }
    >
      <div className="h-full w-full">
        <div className="w-full flex flex-col space-y-3">
          <div>
            <h3 className="text-base font-bold">Unit Status</h3>
            <LineItem
              label="Community WiFi Enabled"
              value={unitDetails?.community_wifi_enabled}
            />
            <LineItem
              label="Has Z-Wave Locks"
              value={unitDetails?.has_zwave_locks}
            />
            <LineItem
              label="Has Non-Z-Wave Locks"
              value={unitDetails?.has_non_zwave_locks}
            />
            <LineItem
              label="Access Control Providers"
              value={unitDetails?.access_control_provider_names.join(", ")}
            />
          </div>
          <div>
            <h3 className="text-base font-bold">Property Lock Settings</h3>
            <LineItem
              label="Lock Vacant Units"
              value={unitDetails?.lock_vacant_units}
            />
            <LineItem
              label="Lock Audio"
              value={capitalize(unitDetails?.lock_audio)}
            />
            <LineItem label="Privacy Lock" value={unitDetails?.privacy_lock} />
            <LineItem
              label="Auto-Lock Timeout"
              value={unitDetails?.auto_lock_timeout}
            />
            <LineItem
              label="Battery Threshold"
              value={
                unitDetails?.battery_threshold
                  ? `${unitDetails?.battery_threshold}%`
                  : undefined
              }
            />
          </div>
          <div>
            <h3 className="text-base font-bold">Tours</h3>
            <LineItem label="Tour-able" value={unitDetails?.tourable} />
            <LineItem
              label="Visible On selftournow.com"
              value={unitDetails?.visible_on_selftournow}
            />
            <div className="flex justify-between py-2 space-x-2">
              <Button
                asChild
                variant="secondary"
                size="sm"
                className="flex-1 space-x-1"
                disabled={!unitDetails?.group_tour_uuid}
              >
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={groupsTourUrl({ uuid: unitDetails?.group_tour_uuid })}
                >
                  <span>Tour Group</span>
                  <ExternalLinkIcon size={12} />
                </a>
              </Button>
              <Button
                asChild
                variant="secondary"
                size="sm"
                className="flex-1 space-x-1"
                disabled={!unitDetails?.group_tour_uuid}
              >
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={homeTourUrl({
                    uuid: unitDetails?.group_tour_uuid,
                    unit_id: unit?.id,
                  })}
                >
                  <span>Tour Home</span>
                  <ExternalLinkIcon size={12} />
                </a>
              </Button>
            </div>
          </div>
          <div>
            <div className="flex justify-between items-center pb-1">
              <h3 className="text-base font-bold">Integrations</h3>
              <Button variant="ghost" size="icon" asChild>
                <Link to={links.group(group?.id)} target="_blank">
                  <ExternalLinkIcon size={14} />
                </Link>
              </Button>
            </div>
            <h4 className="text-sm font-semibold text-muted-foreground">
              Group
            </h4>
            <div className="flex flex-col space-y-1 pb-2">
              {unitIntegrations?.groups?.map((integration) => (
                <Integration
                  key={integration.provider}
                  name={integration.provider}
                  enabled={integration.enabled}
                  locked={integration.locked}
                />
              )) || (
                <div className="text-sm font-semibold">
                  No group integrations
                </div>
              )}
            </div>
            <h4 className="text-sm font-semibold text-muted-foreground">
              Organization
            </h4>
            <div className="flex flex-col space-y-1 pb-4">
              {unitIntegrations?.organizations?.map((integration) => (
                <Integration
                  key={integration.provider}
                  name={integration.provider}
                  enabled={integration.enabled}
                  locked={integration.locked}
                />
              )) || (
                <div className="text-sm font-semibold">
                  No organization integrations
                </div>
              )}
            </div>
          </div>
        </div>

        {permissions.resident_details ? (
          <div>
            <h3 className="text-base font-bold">Resident Notifications</h3>
            <div className="flex flex-col space-y-1 pb-4">
              <LineItem label="Manual" value={notify_resident_config?.Manual} />
              <LineItem
                label="Humidity"
                value={notify_resident_config?.Humidity}
              />
              <LineItem label="Leak" value={notify_resident_config?.Leak} />
              <LineItem
                label="Low Battery"
                value={notify_resident_config?.["Low Battery"]}
              />
              <LineItem label="Temp" value={notify_resident_config?.Temp} />
            </div>
          </div>
        ) : null}

        <div>
          <h3 className="text-base font-bold">User Notes</h3>

          <div className="flex flex-col space-y-1">
            {unitNotes?.map((note) => (
              <div key={note.id} className="p-1 rounded-sm bg-muted">
                <p className="text-sm font-semibold">{note.body}</p>
                <p className="text-xs italic text-muted-foreground">
                  {`${note.first_name || ""} ${note.last_name || ""}`.trim()} (
                  {note.email})
                </p>
                <p className="text-xs italic text-muted-foreground">
                  {formatDate({ date: note.inserted_at })}
                </p>
              </div>
            )) || <div className="text-sm font-semibold">No notes</div>}
          </div>
        </div>
      </div>
    </SideDrawer>
  );
};

const LineItem = ({
  label,
  value,
  color,
}: {
  label: string;
  value?: string | boolean;
  color?: "muted" | "success" | "destructive";
}) => {
  let v = "N/A";

  if (isBoolean(value)) {
    v = value ? "Yes" : "No";
  } else if (value) {
    v = value;
  }

  return (
    <div className="flex justify-between items-center space-x-1">
      <p className="text-sm font-semibold">{label}</p>
      <span className="flex-1 h-3 border-b border-border border-dashed" />
      <p
        className={cn("text-sm italic", {
          "text-muted-foreground": color === "muted" || !value,
          "text-success": color === "success" || value === true,
          "text-destructive": color === "destructive" || value === false,
        })}
      >
        {v}
      </p>
    </div>
  );
};

const Integration = ({
  name,
  enabled,
  locked,
}: {
  name: string;
  enabled?: boolean;
  locked?: boolean;
}) => (
  <div className="flex justify-between items-center space-x-1">
    <p className="text-sm font-semibold">{name}</p>
    <span className="flex-1 h-3 border-b border-border border-dashed" />
    {locked ? (
      <Badge variant="secondary" className="w-16 justify-center">
        Locked
      </Badge>
    ) : (
      <EnabledBadge value={enabled} />
    )}
  </div>
);

const EnabledBadge = ({ value }: { value?: boolean }) => (
  <Badge
    variant={
      value === undefined ? "outline" : value ? "success" : "destructive"
    }
    className="w-16 justify-center"
  >
    {value === undefined ? (
      <span>&mdash;</span>
    ) : value ? (
      "Enabled"
    ) : (
      "Disabled"
    )}
  </Badge>
);
