import { Link, useParams } from "react-router-dom";
import { ArrowLeftIcon } from "@radix-ui/react-icons";

import { BreadcrumbResponsive } from "@/_v2/components/composites/breadcrumb-responsive";
import { useCurrentUnit } from "@/_v2/api/units";

import { UnitPageDbInfo } from "./unit-page-details/unit-page-db-info";

export const UnitPageHeader = () => {
  const { unitId } = useParams<{ unitId: string }>();
  const { data, isFetching } = useCurrentUnit();

  const organizationId = data?.organization.id;
  const groupId = data?.group.id;

  return (
    <div className="flex items-center justify-between mb-3">
      <BreadcrumbResponsive
        items={[
          {
            label: data?.organization.name || "Organization",
            href: organizationId
              ? `/organizations/${organizationId}`
              : undefined,
            loading: !data || isFetching,
          },
          {
            label: data?.group.marketing_name || "Group",
            href: groupId ? `/groups/${groupId}` : undefined,
            loading: !data || isFetching,
          },
          {
            label: data?.unit.marketing_name || "Unit",
            loading: !data || isFetching,
          },
        ]}
      />

      <UnitPageDbInfo />

      <Link
        className="text-xs hover:underline bg-muted px-2 py-1 rounded-lg flex items-center gap-1"
        to={`/units/${unitId}`}
      >
        <ArrowLeftIcon className="size-3 inline-block" />
        <span>go to old unit page</span>
      </Link>
    </div>
  );
};
