import { useContext, useState, useEffect } from "react";
import {
  View,
  TouchableHighlight,
  TouchableOpacity,
  StyleSheet,
} from "react-native";
import { useLocation } from "react-router-dom";
import upperCase from "lodash/upperCase";
import capitalize from "lodash/capitalize";

import { Typography, useTheme, TextInputField } from "@smartrent/ui";
import { CloseCircleSolid, Search, ArrowLeft } from "@smartrent/icons";

import { useIsDesktop } from "@/hooks/breakpoints";
import { useDocumentTitle } from "@/hooks/use-document-title";
import { EM_DASH } from "@/utils/chars";
import Layout from "@/layout/Layout";
import Context from "@/layout/Context";
import { useGetUser } from "@/api/alloy-install/users";
import RadioFilters from "@/common/RadioFilters";
import { JobList } from "@/common/jobs/JobList";
import { ProjectList } from "@/common/projects/ProjectList";
import Helpers from "@/lib/helpers";

import ValueLabel from "@/common/typography/ValueLabel";
import { formatPhone } from "@/utils";

const INITIAL_TAB = "jobs";

export default function UserDetails() {
  useDocumentTitle("Alloy Install User Details");
  const isDesktop = useIsDesktop();
  const context = useContext(Context);
  const { colors } = useTheme();
  const { data: user, isSuccess } = useGetUser(context.match.params.userId);
  const [tab, setTab] = useState(INITIAL_TAB);
  const location = useLocation();
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    setTab(Helpers.qsFromLocation(location)?.tab || INITIAL_TAB);
  }, [location]);

  return (
    <Layout>
      <View style={stylesheet.root}>
        <View style={stylesheet.header}>
          <View style={stylesheet.flexRow}>
            <TouchableHighlight
              activeOpacity={0.8}
              underlayColor="transparent"
              onPress={() => {
                context.history.push("/alloy-install/users");
              }}
            >
              <View style={stylesheet.flexRow}>
                <ArrowLeft />
                <Typography style={stylesheet.headerTitleLabel}>
                  Users /{" "}
                </Typography>
                <Typography font="semibold">
                  {user?.first_name} {user?.last_name}
                </Typography>
              </View>
            </TouchableHighlight>
          </View>

          <View style={stylesheet.userPersonalDetails}>
            <Typography style={stylesheet.userName}>
              {user?.first_name} {user?.last_name}
            </Typography>

            <ValueLabel
              value={user?.phone ? formatPhone({ phone: user.phone }) : EM_DASH}
              label="Phone"
            />
            <ValueLabel
              value={user?.email}
              label="Email"
              style={stylesheet.userDisplayValue}
            />
            <ValueLabel
              value={upperCase(user?.role)}
              label="Role"
              style={stylesheet.userDisplayValue}
            />
          </View>
        </View>

        <View
          style={[
            stylesheet.tableControls,
            isDesktop ? stylesheet.row : stylesheet.column,
          ]}
        >
          <View style={stylesheet.searchContainer}>
            <TextInputField
              StartAdornment={Search}
              label={`Search for ${capitalize(tab)}`}
              textInputProps={{
                value: searchTerm,
                onChangeText: setSearchTerm,
              }}
              EndAdornment={() =>
                searchTerm ? (
                  <TouchableOpacity onPress={() => setSearchTerm("")}>
                    <CloseCircleSolid color={colors.iconsWithinComponents} />
                  </TouchableOpacity>
                ) : null
              }
            />
          </View>

          {isDesktop ? <View style={stylesheet.spacer} /> : null}

          <View style={stylesheet.tabs}>
            <RadioFilters
              onChange={(value: string) => {
                context.history.replace(`?tab=${value}`);
                setSearchTerm("");
              }}
              currentScreen={tab}
              screens={[
                { label: "Jobs", key: "jobs" },
                { label: "Projects", key: "projects" },
              ]}
            />
          </View>
        </View>

        {isSuccess && tab === "jobs" ? (
          <JobList searchValue={searchTerm} assignedToUserId={user?.id} />
        ) : null}

        {isSuccess && tab === "projects" ? (
          <ProjectList searchValue={searchTerm} assignedToUserId={user?.id} />
        ) : null}
      </View>
    </Layout>
  );
}

const stylesheet = StyleSheet.create({
  root: {
    padding: 16,
  },
  header: {
    paddingBottom: 16,
  },
  flexRow: {
    flexDirection: "row",
  },
  headerTitleLabel: {
    marginLeft: 8,
  },
  userName: {
    fontSize: 32,
    fontWeight: "bold",
    marginBottom: 12,
  },
  userDisplayValue: {
    marginTop: 8,
  },
  tableControls: {
    flexDirection: "row",
    justifyContent: "center",
    marginTop: 22,
  },
  userPersonalDetails: {
    marginTop: 22,
  },
  spacer: {
    flex: 3,
  },
  tabs: {
    flex: 2,
  },
  searchContainer: {
    flex: 2,
  },
  column: {
    flexDirection: "column",
  },
  row: {
    flexDirection: "row",
  },
});
