import type { SRFeatureFlagsProps } from "@shared-types/supportrent";

import { instance } from "@/lib/hooks";
import { createAxiosMutation, createAxiosQuery } from "@/hooks/react-query";

export const useSRFeatureFlagsQuery = createAxiosQuery(
  "sr-feature-flags",
  async () => {
    return instance.get<SRFeatureFlagsProps[]>(`/sr-feature-flags`);
  }
);

export const useSRFeatureFlagMutation = createAxiosMutation(
  async ({ name, enabled }: { name: string; enabled: boolean }) => {
    return instance.put<SRFeatureFlagsProps>(`/sr-feature-flags/${name}`, {
      enabled,
    });
  },
  {
    onSuccess: (queryClient) => {
      queryClient.invalidateQueries(["sr-feature-flags"]);
    },
    successToast: () => ({
      message: "Updated feature flag. Please refresh the page to see changes.",
    }),
    errorToast: () => ({
      message: "Failed to update feature flag",
    }),
  }
);
